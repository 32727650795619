.cameraCheckWindowContainer {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 40;
  width: 100%;
  height: 100%;
  background: #1B1D21;
  padding: 0px 0px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 29px;
}

.cameraCheckWindow_TopControls {
  margin: 0px 32px;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--white);
}

.cameraCheckWindow_BackButton {
  display: flex;
  align-items: center;
  gap: 8px;
}

.cameraCheckWindow_BackButton>div:nth-child(2) {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.cameraCheckWindow_BackButton span {
  color: var(--table-table-name, #9FAAB8);
}

.cameraCheckWindow_VideoPreview {
  display: flex;
  width: 1325px;
  height: 719px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.cameraCheckWindow_VideoPreview video {
  border-radius: 8px;
  width: 1325px;
  height: 719px;
  flex-shrink: 0;
}

.cameraCheckWindow_Timer {
  width: 160px;
}

.cameraCheckWindow_Timer>span {
  /* width: 75px; */
  /* flex: 1; */
}

.cameraCheckWindow_Timer>span:first-child {
  color: var(--colorTextHeading, rgba(255, 255, 255, 0.85));
  min-width: 50px;
  max-width: 87px;
  /* LG/LG Strong */
  font-family: "SFProTextRegular";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.cameraCheckWindow_Timer,
.cameraCheckWindow_Timer>span:nth-child(2) {
  color: var(--colorTextDescription, rgba(255, 255, 255, 0.45));
  /* flex: 1; */
  /* Base/Base Normal */
  font-family: "SFProTextRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}