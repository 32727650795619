.hasFiltersWhiteDot {}

.activeFilter>span {
  color: white !important;
}

.activeFilter>svg>path {
  stroke: white;
}

input[type=radio] {
  appearance: none;
  background-color: transparent;
  border-radius: 50%;
  padding: 0;
  border-width: 0px;
  width: 14.5px;
  height: 14.5px;
  padding-top: 7px;
}

input[type=radio]::before {
  content: url('../../../assets/img/RadioUnselected.svg');
  display: block;
  position: relative;
  cursor: pointer;
}

input[type=radio]:checked::before {
  appearance: none;
  content: url('../../../assets/img/RadioSelected.svg');
  display: block;
  position: absolute;
  cursor: pointer;
}

.new-folder-input {
  flex: 1;
}

.new-folder-input::selection {
  background-color: var(--colorPrimaryHover, rgba(60, 137, 232, 1));
  border-radius: 4px;
  opacity: 0.4;
}

.new-folder-input::placeholder {
  color: var(--colorTextPlaceholder, rgba(255, 255, 255, 0.25));

  /* Base/Base Normal */
  font-family: "SFProTextRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.bnt-line-height>span.ant-btn-icon {
  line-height: 0;
}