.eventEditModal{
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.15);
    display: inline-flex;
    padding: 16px;
    min-width: 300px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    position: absolute;
    max-width: 320px;
    z-index: 30;
}
.editedEventModal_EventTime {
    align-items: center;
    width: 100%;
}
.eventEditModal span {
    color: var(--table-table-name, #9FAAB8);

    /* inter_16/16_regular_(24) */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.eventEditModal_Header {
    width: 100%;
    color: var(--black, #212121);
    gap: 8px;
}
.eventEditModal_Header >div:first-child{
    /* inter_20/20_sb_(24) */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.eventEditModal > div {
    display: flex;
    /*flex-direction: row;*/
}
.eventEditModal_Divider{
    width: 100%;
    border-bottom: 1px solid #C1CDDE;
}
.eventEditModal_CameraBlock{

}
.eventEditModal_VideoFilesBlock{

}
.eventEditModal_StatsBlock{
    width: 100%;
    gap: 12px;
}
.eventEditModal_StatsBlock div{
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}
.eventEditModalFooter > div {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}
.eventEditModalFooter span {
    color: var(--red, #F84949);
}
