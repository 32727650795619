.ant-message-top {
  top: unset !important;
  bottom: 25px !important;
  right: 25px !important;
}

.notification-toast {
  border-radius: 8px;
  border: 1px solid var(--colorSplit, rgba(255, 255, 255, 0.06));
  background-color: var(--colorBgElevated, #3D4249);
}

.notification-toast.error {
  border: 1px solid var(--colorErrorBorder, #58181C);
  background: var(--colorErrorBg, #2A1215);
}

.notification-toast.warning {
  border: 1px solid var(--colorWarningBorder, #594214);
  background: var(--colorWarningBg, #2B2111);
}

.notification-toast>div {
  /* width: 510px; */
}

.notification-toast>div>div.ant-message-custom-content {
  display: flex !important;
  flex-direction: row !important;
  gap: 10px !important;
  align-items: center !important;
}

.notification-toast>div>div>span:nth-child(2) {
  width: 100%;
}

.toast-content {
  color: var(--colorText);
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex-direction: row !important;
  text-align: left !important;
  font-size: 14px;
}