.activeTeamSwitchContainer {
    cursor: pointer;
    display: flex;
    width: auto;
    padding: 10px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex-shrink: 0;

    border-radius: 8px;
    border: 1px solid var(--new-main, #3A78F1);
    background: var(--new-main, #3A78F1);
    color: var(--white, #FFF);

    /* inter_16/16_medium_(24) */
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.teamsAddModal{
    border-radius: 8px;
    border: 1px solid var(--table-table-stroke, #C1CDDE);
    background: #FFF;
    margin: auto;
    display: inline-flex;
    padding: 24px 16px 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    max-width: 350px;
}
.teamsAddModal:focus {
    outline: none;
}
.modalTitle{
    color: var(--black, #212121);

    /* inter_20/20_sb_(24) */
    font-size: 20px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.teamsListContainer{
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 8px 0 8px;
    width: 100%;
    min-height: 200px;
    min-width: 350px;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: auto;
}
.teamsListContainer div {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    gap: 6px;
    color: var(--table-table-name, #9FAAB8);

    /* inter_16/16_regular_(24) */
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.modalControlButtons{
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
}
.modalControlButtons div {
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex: 1 0 0;
}
.modalControlButtons > div:nth-child(1){
    color: var(--black);
    border: 1px solid var(--table-table-stroke, #C1CDDE);
}
.modalControlButtons > div:nth-child(2){
    color: var(--white);
    background: var(--new-main, #3A78F1);
}
.modalControlButtons > div:nth-child(2).disabled {
    background: rgba(58, 120, 241, 0.29);
    color: white!important;
    cursor: unset!important;
}
.newTeamSelected{
    position: relative;
    max-width: 24px;
    top: -3.1rem;
    /*left: -11rem;*/
}
input[name=newTeam]{
    padding-left: 30px;
    color: var(--black, #212121);
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.activeTeamSelector{
    cursor: pointer;

    border-radius: 8px;
    border: 1px solid var(--table-table-stroke, #C1CDDE);
    background: var(--white, #FFF);

    display: flex;
    padding: 10px 16px;
    align-items: center;
    justify-items: center;
    justify-content: space-between;
    gap: 8px;
    flex-shrink: 0;
    flex-direction: row;
    color: var(--new-main)!important;
}
.activeTeamSelectorSpan {
    color: var(--new-main)!important;
}
.teamsSelectionList div{
    padding-bottom: 8px;
}
.activeTeamSelector div:nth-child(1),
.teamsSelectionList div{
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    /*color: var(--new-main, #3A78F1);*/

    /* inter_16/16_medium_(24) */
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.teamsSelectionList{
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: space-around;
    width: 100%;
    color: var(--table-table-name);
}
.teamsSelectionList > div:hover {
    background-color: #F6F7F7;
}
/*.teamsSelectionList div:first-child {*/
/*    padding-top: 8px;*/
/*}*/

.teamsSelectionList div:last-child {
    border-bottom: 1px solid var(--table-table-stroke, #C1CDDE);

}
.activeTeamSelector.opened {
    position: absolute;
    width: 100%;
    flex-direction: column;
    z-index: 10;
}
.activeTeamSelector.opened > div {
    /*padding-top: 10px;*/
    /*padding-bottom: 16px;*/
}
.activeTeamSelector.opened > div:nth-child(1) {
    border-bottom: 1px solid var(--table-table-stroke);
    padding-bottom: 8px;
}
/*.activeTeamSelector.opened > div:last-child {*/
/*    width: 100%;*/
/*    border-top: 1px solid var(--table-table-stroke);*/
/*    padding-top: 8px;*/
/*}*/
