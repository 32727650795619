.stats-name {
  color: rgba(0, 0, 0, 0.45);
  text-align: right;

  /* Base/Base Strong */
  font-family: SFProTextRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 157.143% */
  /* opacity: 0.45;
  --webkit-opacity: 0.45;
  --moz-opacity: 0.45; */
}

.team-stats-mode-selector {
  margin: 8px;
  width: auto !important;
}

.stats-header {
  /* min-width: 70px; */
  text-align: start;
}

.block-colum-player-stats {
  padding: 0px !important;
}

.block-column-total-player {
  display: flex;
  width: 70px;
  height: 32px;
  padding: 0px 8px 0px 8px;
  align-items: center;
  border-radius: 8px;
  background: var(--controlOutline, #E6F4FF);

  color: var(--colorPrimaryBase, #1677FF);
  font-family: "Roboto Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
  text-transform: uppercase;
}

.block-wrapper.left {
  width: auto;
  flex: 1;
}

.stats-header-v2 {
  overflow: hidden;
  color: var(--colorTextLabel, rgba(0, 0, 0, 0.65));
  text-overflow: ellipsis;
  white-space: nowrap;

  /* Condensed/Base Strong */
  font-family: "Roboto Condensed";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
  letter-spacing: 0.98px;
  text-transform: uppercase;
}

.block-wrapper.right {
  min-width: 559px;
}

.tree-container {
  width: 100%;
}

.tree-container .ant-tree-indent-unit {
  width: 10px;
}

.collapsed {
  max-height: 0px;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.5s ease, opacity 0.4s ease;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.expanded {
  max-height: 1000px;
  opacity: 1;
  transition: max-height 1s linear, opacity 0.5s ease;
}