.filterPanels {
  display: flex;
  align-items: stretch;
  gap: 32px;
  min-width: 450px;
  flex: 1;
  /*max-width: 1200px;*/
}

.leftPanel {
  /* display: flex;
    min-width: 116px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px; */
  height: 100%;
  overflow-y: auto;
  display: flex;
  width: 131px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.teamFilterSelector {
  display: flex;
  /* height: 24px; */
  align-items: center;
  align-self: stretch;
  color: #9FAAB8;
}

.teamFilterSelector.activeFilterTeam {
  color: var(--white, #FFF);
}

/* .rightPanel{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
} */
/* .rightPanel>div:first-child{
    display: flex;
    align-items: center;
    gap: 6px;
    align-self: stretch;
} */
/* .rightPanel>div:nth-child(2){
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 32px;
} */
/*.playersColumn{*/
/*    display: flex;*/
/*    width: 240px;*/
/*    flex-direction: column;*/
/*    align-items: flex-start;*/
/*    gap: 24px;*/
/*}*/
.columnBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  text-align: left;
  min-width: 170px;
}

/*.columnBlock>div:first-child{*/
/*    border-bottom: 1px #9FAAB8 solid;*/
/*    width: 100%;*/
/*}*/
.columnBlock>div.hr {
  height: 1px;
  background-color: #9FAAB8;
  width: 100%;
  margin: 0px !important;
}

.columnBlock>div {
  display: flex;
  gap: 6px;
}

.filtersActive {
  color: var(--white, #FFF);
  /* inter_16/16_medium_(24) */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

.calendarSelector {
  border-radius: 6px;
  border: 1px solid var(--table-table-stroke, #C1CDDE);
  background: var(--gray-light-2, #576170);
  width: fit-content;
}

.my-selected.rdp-day_range_start:hover,
.my-selected.rdp-day_range_end:hover {
  background-color: var(--new-main-light) !important;
  color: var(--white) !important;
}

.rdp-day:hover {
  background: var(--gray-light, #3F4752) !important;
  color: var(--table-table-name, #9FAAB8) !important;
}

.rdp-day:active {
  border: 1px solid var(--new-main-light, #3A78F1);
}

.my-selected.rdp-day_range_start,
.my-selected.rdp-day_range_end {
  /*background-color: var(--new-main-light) !important;*/
  border: 1px solid var(--new-main-light) !important;
}

.my-selected.rdp-day_range_start {
  border-radius: 4px 0 0 4px !important;
}

.my-selected.rdp-day_range_end {
  border-radius: 0 4px 4px 0px !important;
}

.my-selected.rdp-day_range_middle {
  background-color: transparent !important;
  /*opacity: 50%;*/
  border-top: 0.5px solid var(--new-main-light) !important;
  border-bottom: 0.5px solid var(--new-main-light) !important;
  border-left: 0 !important;
  border-radius: 0 !important;
  border-right: 0 !important;
}

.my-selected.rdp-day_range_middle:hover {
  background-color: var(--new-main-light) !important;
  color: var(--white);
}

.my-selected:not([disabled]) {
  font-weight: bold;
  border: 2px solid currentColor;
}

.my-selected:hover:not([disabled]) {
  border-color: blue;
  color: white;
}

.my-today {
  font-weight: bold;
  /*border-radius: 4px!important;*/
  border: transparent;
  background: var(--new-main-light);
  /*background: linear-gradient(rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%);*/
}

.rdp-caption_label,
.rdp-head_cell {
  text-transform: capitalize !important;
  color: var(--table-table-stroke, #C1CDDE);
  text-align: center;

  /* inter_13/13_sb_(16) */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 123.077% */
}

.rdp-day {
  color: var(--white, #FFF);
  text-align: center;

  /* inter_13/13_regular_(16) */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 123.077% */

  display: flex;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 4px !important;
}

.rdp-day:hover {
  border-radius: 4px !important;
  color: var(--new-main)
}

.rdp {
  --rdp-cell-size: 45px !important;
  display: flex;
  justify-content: center;
  margin: 12px !important;
}

.rdp-nav_button {
  height: 24px !important;
  width: 24px !important;
}

.rdp-nav_button:hover {
  background-color: transparent !important;
}

input.dateRange {
  padding: 12px 8px;
  border-radius: 6px;
  border: 1px solid var(--table-table-stroke, #C1CDDE);
  background: var(--gray-light, #3F4752);
  color: var(--white);
  width: 119.5px;
}

.gamesFilterContainer {
  display: flex;
  width: 300px;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 6px;
  background: var(--colorBgSpotlight, #4D5765);
}

.gamesFilterContainer_title {
  align-self: stretch;
  color: var(--colorTextHeading, rgba(255, 255, 255, 0.85));

  /* Heading/Heading 5 */
  font-family: "Roboto Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.96px;
  text-transform: uppercase;
}

.filterContainer.matches {
  gap: 16px;
}

.filterContainer.metrics {
  align-items: self-start;
  justify-content: space-between;
}

.games_filter_content_wrapper {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  max-height: 380px;
}

.games_filter_separate_filters_wrapper {
  display: flex;
  /* padding: 12px 0px; */
  align-items: flex-start;
  /* gap: 40px; */
  flex: 1 0 0;
  border-radius: 8px;
  flex-direction: column;
  align-self: stretch;
}

.games_filter_columns_wrapper {
  display: flex;
  padding: 12px 0px;
  align-items: flex-start;
  gap: 40px;
  flex: 1 0 0;
  flex-direction: row;
  border-radius: 8px;
}

.games_filter_matches_wrapper {
  display: flex;
  width: 300px;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  overflow-y: auto;
  overflow-x: hidden;
  height: 376px;
  border-radius: 6px;
  background: var(--colorBgSpotlight, #4D5765);
}

.games_filter_other_filters {
  display: flex;
  width: 428px;
  padding-bottom: 5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.tournament_name_filter_label {
  overflow: hidden;
  color: var(--colorTextHeading, rgba(255, 255, 255, 0.85));
  text-overflow: ellipsis;

  /* Base/Base Normal */
  font-family: SFProTextRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.tournament_name {
  line-height: 22px;
  /* 157.143% */
  overflow: hidden;
  width: 100%;
}

.tournament_name>span:nth-child(1) {
  overflow: visible;
}

.tournament_name>span {
  overflow: hidden;
  color: var(--colorTextHeading, rgba(255, 255, 255, 0.85));
  text-wrap: nowrap;
  text-overflow: ellipsis;

  /* Base/Base Normal */
  font-family: SFProTextRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.tournament_name> :where(.css-dev-only-do-not-override-laoxba).ant-checkbox+span {
  padding-inline-start: 12px;
  padding-inline-end: 8px;
}

.games_filter_game_block {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.games_filter_game_block_name {
  align-self: stretch;
  overflow-x: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;

  /* color: var(--colorTextHeading, rgba(255, 255, 255, 0.85)); */
  font-family: "Roboto Condensed";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: 0.84px;
  text-transform: uppercase;
}

.games_filter_game_block_info {
  display: flex;
  align-items: center;
  gap: 6px;
  align-self: stretch;

  /* color: var(--colorTextLabel, rgba(0, 0, 0, 0.65)); */
  font-family: "SFProTextRegular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  /* text-transform: uppercase; */
}

.games_filter_game_block_info .divider {
  width: 1px;
  height: 10px;
  background: var(--colorFillSecondary, rgba(255, 255, 255, 0.12));
}

.games_filter_game_block_info>div:last-child {
  text-overflow: ellipsis;
  overflow-x: hidden;
  text-wrap: nowrap;
}