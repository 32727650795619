.folder-row-wrapper {
  display: flex;
  height: 32px;
  padding: 0px 0px 0px 4px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}

.folder-row-wrapper>div {
  overflow: hidden;
  color: var(--colorTextHeading, rgba(255, 255, 255, 0.85));
  text-overflow: ellipsis;
  padding-right: 12px;
  /* Base/Base Normal */
  font-family: "SFProTextRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.elements-count {
  color: var(--colorTextQuaternary, rgba(255, 255, 255, 0.25));
  text-align: right;
  font-family: "Roboto Condensed";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
  text-transform: uppercase;
}

.playlist-folder-element {
  display: flex;
  height: 32px;
  padding: 0px 12px 0px 4px;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--colorFillQuaternary, rgba(255, 255, 255, 0.04));

  overflow: hidden;
  color: var(--colorTextLabel, rgba(255, 255, 255, 0.65));
  text-overflow: ellipsis;

  /* Base/Base Normal */
  font-family: "SFProTextRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.hiddenPlaylistsBlock {
  opacity: 0;
  height: 0;
  display: none;
  overflow: hidden;
  transition:
    display 2s ease,
    opacity 2s ease,
    height 2s ease;
}

.hiddenPlaylistsBlock.expandDown {
  display: flex;
  opacity: 1;
  height: auto;
  transition:
    display 2s ease,
    opacity 2s ease,
    height 2s ease;
}

.modal-title-dark {
  color: var(--character-title-85, rgba(255, 255, 255, 0.85));

  /* H5/medium */
  font-family: "Roboto Bold";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

.modal-content-dark {
  color: var(--character-title-85, rgba(255, 255, 255, 0.85));

  /* Body/regular */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.ant-notification-notice-message {
  display: none;
}

.ant-notification-notice-with-icon {
  display: flex;
  align-items: center !important;
}

.ant-notification-notice-description {
  width: 100%;

}

.folder-name {
  overflow: hidden;
  color: var(--colorTextHeading, rgba(255, 255, 255, 0.85));
  text-overflow: ellipsis;
  text-wrap: nowrap;
  /* Base/Base Normal */
  font-family: "SFProTextRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

/* .ant-tree-switcher.ant-tree-switcher-noop::before {
  width: 0;
}
.ant-tree-switcher.ant-tree-switcher-noop{
  width: 0;
} */
span.ant-tree-switcher-noop:has(div.level-0) {
  width: 0!important;
}
.ant-tree-node-content-wrapper{
  display: flex;
  align-items: center !important;
  gap: 4px;
}
.ant-tree-title{
  width: 100%;
}
@keyframes inAnimation {
  0% {
    /* opacity: 1; */
    /* visibility: visible; */
    height: 32px;
    /* transform: translateY(-150px); */
  }

  100% {
    /* opacity: 0; */
    /* visibility: hidden; */
    height: 0;
    /* transform: translateY(0); */
  }
}

@keyframes outAnimation {
  0% {
    /* opacity: 0; */
    /* visibility: hidden; */
    height: 0;

    /* transform: translateY(0); */
  }

  100% {
    /* opacity: 1; */
    /* visibility: visible; */
    height: 32px;

    /* transform: translateY(150px); */
  }
}