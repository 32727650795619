.success-checkbox {
  border-radius: 6px;
  width: 20px;
  height: 20px;
  background: var(--colorPrimaryBgHover, #BAE0FF);
}

.fail-checkbox {
  border-radius: 6px;
  width: 20px;
  height: 20px;
  background: var(--colorTextDisabled, rgba(0, 0, 0, 0.25));
}

.success-checkbox span.ant-checkbox-inner::before {
  content: '';
  position: absolute;
  top: 13%;
  left: 10%;
  background-image: url('../../../assets/img/icons/faSuccessCheckIcon.svg');
  width: 12px;
  height: 12px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.fail-checkbox span.ant-checkbox-inner::after {
  display: none !important;
}

.fail-checkbox span.ant-checkbox-checked .ant-checkbox-inner {
  position: relative;
}

.fail-checkbox span.ant-checkbox-checked .ant-checkbox-inner::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  background-image: url('../../../assets/img/icons/faFailCrossIcon.svg');
  width: 10px;
  height: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: translate(-50%, -50%);
}

.fail-checkbox span.ant-checkbox-inner::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: translate(-50%, -50%);
  background-image: url('../../../assets/img/icons/faFailCrossIconNotTransparent.svg');
}

.fail-checkbox>span.ant-checkbox-checked .ant-checkbox-inner::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.select-divider:after {
  content: '';
  width: 100%;
  height: 1px;
  border: 1px black solid;
}