.stats-name {
  color: rgba(0, 0, 0, 0.45);
  text-align: right;

  /* Base/Base Strong */
  font-family: SFProTextRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 157.143% */
  /* opacity: 0.45;
  --webkit-opacity: 0.45;
  --moz-opacity: 0.45; */
}

.block-colum-player-stats {
  padding: 0px !important;
}

.block-column-total-player {
  display: flex;
  width: 70px;
  height: 32px;
  padding: 0px 8px 0px 8px;
  align-items: center;
  border-radius: 8px;
  background: var(--controlOutline, #E6F4FF);

  color: var(--colorPrimaryBase, #1677FF);
  font-family: "Roboto Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
  text-transform: uppercase;
}

.tree-container {
  width: 100%;
}

.tree-container .ant-tree-indent-unit {
  width: 10px;
}

.collapsed {
  max-height: 0px;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.5s ease, opacity 0.4s ease, margin-top 0.4s ease;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.expanded {
  max-height: 1000px;
  opacity: 1;
  margin-top: 4px !important;
  transition: max-height 1s linear, opacity 0.5s ease,
    margin-top 0.4s ease;
}

.select-placeholder {
  color: var(--colorTextLabel, rgba(0, 0, 0, 0.65));
  /* Base/Base Normal */
  font-family: "SFProTextRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.ant-popover-content:has(div div div.personSettingHeader) {
  top: 70px;
}

.playerStatsPopoverHandler {
  text-overflow: ellipsis;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  max-width: 180px;
}
.games-popover-overlay>.ant-popover-content>.ant-popover-inner {
  padding: 0px !important;
}