.labelText {
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));
  text-align: center;

  /* Base/Base Normal */
  font-family: "SFProTextRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}