.playlistCreationControl{
    position: fixed;
    bottom: 32px;
    right: 12px;

    display: flex;
    width: 360px;
    /*height: 40px;*/
    padding: 12px;
    /*flex-direction: column;*/
    /*align-items: flex-start;*/
    gap: 10px;

    border-radius: 8px;
    border: 1px solid var(--table-table-name, #9FAAB8);
    background: var(--new-main);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
}
.playlistCreationControl.additional {
    background: var(--new-main-light);
}
.bottomControlbutton {
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 8px;
    /*flex: 1 0 0;*/
    border-radius: 6px;
    border: 1px solid var(--white, #FFF);
    background: var(--new-main-light);
    color: var(--white, #FFF);
    /* inter_16/16_medium_(24) */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    text-wrap: nowrap;
}
.selectedCountClass{
    border-radius: 6px;
    padding: 8px 12px;
    border: 1px solid var(--white, #FFF);
    background: var(--white, #FFF);
    color: var(--new-main, #3A78F1);
    flex-grow: 0.1;
    justify-content: center;
    /* inter_16/16_sb_(24) */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    overflow: hidden;
    text-wrap: nowrap;

}
.playlistCreationControl >div:nth-child(3),
.playlistCreationControl >div:nth-child(4){
    flex-grow: 0;
    width: 40px!important;
}
.cancelButtonActive {
    background: var(--new-main);
}
