.team-profile-block-column-title {
  display: flex;
  /* height: 32px; */
  padding: 8px 12px 2px 20px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  justify-content: space-between;
  /* Base/Base Normal */
  font-family: "SFProTextRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  text-overflow: ellipsis;
}

.team-profile-player-row {
  overflow: hidden;
  color: var(--colorText, rgba(0, 0, 0, 0.88));
  text-overflow: ellipsis;

  /* Base/Base Normal */
  font-family: "SFProTextRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.team-profile-metric-row {
  height: 32px;
  align-items: center;
  padding-left: 12px;
  padding-right: 8px;
  justify-content: space-between;
}

.team-profile-metric-row.selected,
.team-profile-metric-row:hover {
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.06);
  cursor: pointer;
}