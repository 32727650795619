.personSettingPopover {
  min-width: 612px;
  /* padding-top: 12px;
  padding-bottom: 12px; */
  height: 70vh;
  display: flex;
  flex-direction: column;
}

.personSettingHeader {
  font-size: 16px;
  font-weight: 600;
  margin-left: 24px;
  margin-right: 16px;
  line-height: 32px;
}

.personFilterContainer {
  flex-grow: 1;
  height: 100%;
  margin-left: 12px !important;
}

.personFilterMatches {
  height: calc(67vh - 60px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: 16px !important;
}

.personMatchCountSelect {
  margin-bottom: 16px;
  /* margin-left: 6px; */
}

.treeSelect .ant-tree-switcher {
  color: #000000;
  order: 3;
}

.treeSelect .ant-tree-switcher svg {
  width: 16px;
  height: 16px;
}

.treeSelect .ant-tree-switcher_open {
  transform: rotate(0deg);
}

.personFilterVerticalDivider {
  box-sizing: border-box;
  border-right: 1px solid rgba(5, 5, 5, 0.06);
  padding-right: 12px;
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.treeSelect.with-border .ant-tree-treenode-switcher-open+.ant-tree-treenode-leaf-last {
  margin-bottom: 8px;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}

.personSettingPopover .games_filter_game_block {
  margin-left: 6px;
  margin-bottom: 12px;
}

.personMatchCountSelect {
  display: block;
  border: none !important;
  margin-bottom: 0;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 6px !important;
}

.personMatchCountSelect::before {
  display: none !important;
  content: '';
  border: none !important;
}

.popover-matches-column-header {
  color: var(--colorText, rgba(0, 0, 0, 0.88));
  /* Base/Base Normal */
  font-family: "SFProTextRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.popover-tree-leaf.ant-tree-treenode-leaf-last {
  /* margin-left: -8px; */
}