.dateBlock {
    height: 100%;
    padding: 4px 2px 4px 2px;
    width: calc(100% / 7);
    box-sizing: border-box;
    /*outline: 1px solid var(--table-table-stroke, #C1CDDE);*/
    border: 1px solid var(--table-table-stroke);
    /*box-shadow: 1px 1px 1px 1px var(--table-table-stroke), 1px 1px 1px 1px var(--table-table-stroke);*/
    flex-direction: column;
    min-height: calc(100% / 7);
    flex-basis: calc(100% / 7);
    display: flex;
    gap: 4px;

    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}
.dateBlock span {
    color: var(--table-table-name, #9FAAB8);
}
.dateBlock:nth-child(1){
    border-right: none;
    border-bottom: none;
}
.dateBlock:nth-child(n + 8) {
    border-right: none;
    border-bottom: none;
}
.dateBlock:nth-last-child(n+2):nth-child(-n+6) {
    border-bottom: none;
    border-right: none;
}
.dateBlock:nth-child(14),
.dateBlock:nth-child(21),
.dateBlock:nth-child(28),
.dateBlock:nth-child(35) {
    border-right: 1px solid var(--table-table-stroke);
    border-bottom: 1px solid var(--table-table-stroke);
    border-top: none;
}
.dateBlock:nth-last-child(-n + 8) {
    border-bottom: 1px solid var(--table-table-stroke);
}
.currentMonthDay {
    background-color: var(--white, #FFFFFF);
}
.currentMonthDay:hover{
    border: 1px solid var(--table-table-stroke, #C1CDDE);
    background: var(--table-table, #F7F8FA);
}
.currentMonthDay:active {
    outline: 1px solid #3A78F1!important;
    /*border: 1px solid #3A78F1!important;*/
    background: var(--table-table, #F7F8FA);
}
.otherMonthDay {
    background: var(--table-table, #F7F8FA);
    color: var(--table-table-stroke, #C1CDDE);
}


.currentDay {
    border-radius: 12px;
    background: var(--new-main, #3A78F1);
    color: var(--white);
}
.dayEventsContainer {
    white-space: nowrap;
    padding: 2px 4px 4px 2px;
    gap: 4px;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    position: relative;

    color: var(--black, #212121);

    /* inter_13/13_regular_(16) */
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}
.dayEventsContainer:hover{
    border-radius: 4px;
    outline: 0.5px solid var(--table-table-stroke, #C1CDDE);
    background: var(--bg, #F5F5F7);
}
.activeDate {
    border: 1px solid #3A78F1!important;
}
.viewedEvent {
    border-radius: 4px;
    background: var(--new-main, #3A78F1);
    color: var(--white);
}
.viewedEvent:hover {
    color: var(--black);
}
