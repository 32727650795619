.videoUploaderContainer{
    width: 100%;
    gap: 8px;
    overflow-y: auto;
    max-height: 250px;
}
.fileListItem {
    color: var(--new-main, #3A78F1);

    /* inter_16/16_regular_(24) */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    justify-content: space-between;
    gap: 8px;
    justify-items: flex-start;
    max-width: 100%;
}
.timeZoneSelector {
    display: flex;
    align-items: center;
    color: var(--new-main, #3A78F1);
}
