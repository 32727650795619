.actionModal {
    max-width: 350px;
}
.actionsContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
}
.warning{
    border-radius: 8px;
    border: 1px solid var(--red, #F84949)!important;
    background: #FFF;
}
.actionsContainer div {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid var(--table-table-stroke, #C1CDDE);
}
.actionsContainer .warning{
    border-radius: 8px;
    background: var(--red, #F84949);
    color: var(--white, #FFF);

    /* inter_16/16_medium_(24) */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.information{
    border-radius: 6px;
    background: var(--new-main, #3A78F1);
    color: var(--white);
}
span.modalContent {
    color: var(--table-table-name, #9FAAB8);

    /* inter_16/16_regular_(24) */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}
