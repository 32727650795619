.player-profile-metric-row {
  position: relative;
  cursor: pointer;
}

.player-profile-metric-row.metric-selected.parent::before,
.player-profile-metric-row.parent:hover::before {
  content: '';
  position: absolute;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.04);
  cursor: pointer;
  margin-left: -8px;
  margin-right: 0px !important;
  padding-left: 8px !important;
  padding-right: 0px !important;
  width: calc(100% + 2px) !important;
  height: 42px;
  z-index: 0;
}
.player-profile-metric-row.metric-selected.parent::before {
  background: rgba(0, 0, 0, 0.06);
}
.child-wrapper {
  position: relative;
}

.player-profile-metric-row.child {
  cursor: pointer;
}

div.child-wrapper:has(.player-profile-metric-row.metric-selected.child)::before,
div.child-wrapper:has(.player-profile-metric-row.child:hover)::before {
  content: '';
  position: absolute;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.04);
  cursor: pointer;
  margin-left: -8px;
  margin-right: 0px !important;
  padding-left: 8px !important;
  padding-right: 0px !important;
  width: calc(100% + 2px) !important;
  height: 42px;
  z-index: 0;
  top: -2px;
}
div.child-wrapper:has(.player-profile-metric-row.metric-selected.child)::before {
  background: rgba(0, 0, 0, 0.06);
}
.collapse-button-stats:hover {
  /* background-color: blueviolet;
  padding: 8px 5px; */
}