.event-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  z-index: 1000;
  border: 1px solid rgba(255, 255, 255, 0.85);
  transform-origin: center;
  transition: transform 0.3s;
  border: 1px solid var(--colorDotBorder, rgba(255, 255, 255, 1));
}
.event-dot.small {
  width: 4px;
  height: 4px;
  border: 0.65px solid var(--colorDotBorder, rgba(255, 255, 255, 1));
}
.event-dot:hover {
  transform: scale(1.5);
}

.event-dot.success {
  background-color: var(--colorPrimaryBase, #1677FF);
}

.event-dot.fail {
  background-color: grey;
}

.event-dot.fail::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 49%;
  background-image: url('../../../../assets/img/icons/faFailCrossIcon.svg');
  width: 70%;
  height: 70%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: translate(-50%, -50%);
}


.event-dot.all {
  background-color: var(--colorPrimaryBase, #1677FF);
}
.hover-underline:hover {
  text-decoration: underline;
}