.cameraControlButtonContainer {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}
.cameraControlButtonContainer.disabled button{
    cursor: auto;
    border-radius: 8px;
    border: 1px solid var(--table-table-stroke, #C1CDDE);
    background: #E6EDFC;
    border: 1px solid var(--new-main, #3A78F1);
    opacity: 0.4000000059604645;
    background: var(--white, #FFF);
}
.cameraControlButtonContainer.disabled .pauseButton,
.cameraControlButtonContainer.disabled .stopButton {
    opacity: 0.4000000059604645;
    cursor: auto;
    background: var(--white, #FFF);
}
.readyToRecord{
    border-radius: 10px;
    background: var(--green, #30C321);
    width: 10px;
    height: 10px;
}
.recording{
    border-radius: 10px;
    background: var(--red, #F84949);
    width: 10px;
    height: 10px;
}
.cameraControlButtonContainer button {
    border-radius: 8px;
    border: 1px solid var(--new-main, #3A78F1);
    background: var(--white, #FFF);

    flex-grow: 1;
    display: flex;
    padding: 12px;
    align-items: center;
    align-content: flex-start;
    gap: 12px;
    align-self: stretch;

    color: var(--new-main, #3A78F1);

    /* inter_16/16_regular_(24) */
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    cursor: pointer;
}
.cameraControlButtonContainer > button:focus{
    border: 1px solid var(--new-main, #3A78F1);
}
.pauseButton {
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 8px;
    border: 1px solid var(--orange-orange, #EF9F28);
    background: var(--white, #FFF);
}
.stopButton {
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 8px;
    border: 1px solid var(--red, #F84949);
    background: var(--white, #FFF);
}
