.game_selector_column_1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    width: 204px;
    overflow: hidden;
    height: 376px;
}
.game_selector_column_1 > .title,
.game_selector_column_2 > .title {
    display: flex;
    padding-bottom: 8px;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;


    color: var(--colorTextHeading, rgba(255, 255, 255, 0.85));

    /* Heading/Heading 5 */
    font-family: "Roboto Condensed";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.96px;
    text-transform: uppercase;
}
.game_selector_column_1 > .years {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    overflow-y: auto;
    overflow-x: hidden;
}
.game_selector_column_1 > .divider {
    width: 204px;
    height: 0.5px;
    background: rgba(255, 255, 255, 0.12);
}
.game_selector_column_1 > .last_x_filters{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}
.game_selector_column_2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    width: 204px;
    overflow: hidden;
    height: 376px;
}
.game_selector_column_2 > div:nth-child(2){
    overflow-y: auto;
    overflow-x: hidden;
}