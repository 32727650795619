.video-player-settings-menu>ul {
  /* display: flex; */
  list-style: none;
  /* Remove default bullets */
  padding: 0;
  margin: 0;
  display: inline-flex;
  padding: 0px 4px;
  align-items: flex-start;
  gap: 4px;
  border-radius: 12px;
  background: var(--colorBgLayout, #292D32);
}

.video-player-settings-menu>ul>li.ant-dropdown-menu-item-group {
  flex: 1;
  width: 120px;
  padding: 4px;
}

.video-player-settings-menu>ul>li.ant-dropdown-menu-item-divider {
  width: 1px;
  background-color: rgba(255, 255, 255, 0.06)!important;
  height: 233px!important;
}

.video-player-settings-menu>ul>li>ul>li.ant-dropdown-menu-item-selected::after {
  content: '';
  background-image: url('/src/assets/img/icons/faCheck.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  margin-top: 5px;
}