.invalid {
  border-color: var(--red) !important;
}

.videoListElementNameNoHover {
  display: block;
}

.videoListElementNameNoHover:hover {
  display: none;
}

.videoListElementNameHover {
  display: none;
}

.videoListElementNameHover:hover {
  display: block;
}

.episodeElement {
  display: flex;
  position: relative;
  height: 48px;
  /* padding: 8px 12px 8px 8px; */
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  text-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  /* background-color: var(--colorFillTertiary, rgba(255, 255, 255, 0.08)); */
  color: var(--colorTextLabel, rgba(255, 255, 255, 0.65));

  /* SM/SM Normal */
  font-family: SFProTextRegular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}

.episodeElement>div {
  z-index: 5;
}

.episodeElement.played {
  background-color: rgba(22, 104, 220, 0.3);
}

.smallHeader {
  color: var(--table-table-name, #9FAAB8);

  /* inter_13/13_regular_(16) */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 123.077% */
}

.blue-dot::before {
  content: "";
  background-image: url('../../../assets/img/BlueDotSmall.svg');
  width: 6px;
  height: 6px;
  position: absolute;
  left: -2px;
  z-index: 50;
}

.red-dot::before {
  content: "";
  background-image: url('../../../assets/img/RedDotSmall.svg');
  width: 6px;
  height: 6px;
  position: absolute;
  left: -2px;
  z-index: 50;
}

.episode-rename-input {
  background-color: transparent;
  box-shadow: none;
  background: var(--colorBgElevated, #3D4249);
}

.episode-rename-input::selection {
  background-color: var(--colorPrimaryHover, rgba(60, 137, 232, 1));
  border-radius: 4px;
  opacity: 0.4;
}

.episode-rename-input:focus {
  border: 1px solid var(--Primary-5, #40A9FF) !important;
  box-shadow: none;
}

.episode-rename-input {
  box-shadow: 0px 0px 0px 2px rgba(24, 144, 255, 0.20);
}

.episode-rename-input::placeholder {
  color: var(--colorTextPlaceholder, rgba(255, 255, 255, 0.25));

  /* Base/Base Normal */
  font-family: "SFProTextRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.episode-rename-mode-warpper {
  background: var(--colorBgElevated, #3D4249);
  /* width: 352px; */
  height: 48px;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 9px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
}

.period-name {
  display: flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  /* align-items: center; */
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.06);
  line-height: 17px;
  padding-bottom: 4px;
}

.activeButton {
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.12);
  display: flex;
  align-items: center;
}