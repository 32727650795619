.language-selector{
    justify-content: flex-end;
    margin-bottom: 12px;
    background: transparent;
    border: none;
    font-size: 16px;
    color: #9FAAB8!important;
    display: flex;
    flex-direction: row;
    justify-items: center;
    gap: 4px;
    font-style: normal;
    font-weight: 500;
}
.language-selector.active{
    color: black!important;
}
.language-selector.active > svg > g {
    opacity: 1;
}
.language-selector.active > svg > g > path {
    stroke: black;
}
.language-selector:focus{
    border: none;
    outline: none;
    color: black!important;
}
.language-selector > div {
    color: black;
    margin:12px;
}
.language-dropdown{
    z-index: 2;
    position: absolute;
    font-style: normal;
    font-weight: 500;
    right: 3rem;
    top: 4rem;
    display: flex;
    width: 179px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    color: var(--black, #212121);

    /* inter_16/16_regular_(24) */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    background: white;
    border-radius: 8px;
    background: var(--white, #FFF);

    /* shadow */
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
}
.language-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
