.player-stats-game-row {
  /* background: white; */
}

.player-stats-game-row>td:first-child {
  background-color: white!important;
  border-radius: 12px 0 0 12px!important;
}

.player-stats-game-row>td:last-child {
  background-color: white!important;
  border-radius: 0 12px 12px 0!important;
}
.player-stats-game-table {
  width: 100%;
}
.player-stats-game-table table {
  border-spacing: 0 8px;
}

.player-stats-game-table .ant-checkbox-inner {
  border-spacing: initial;
}

.seasons-title-block {
  color: var(--colorTextLabel, rgba(0, 0, 0, 0.65));
  /* Base/Base Normal */
  font-family: "SFProTextRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  align-content: center;
  height: 40px;
}

.dark-text {
  color: var(--colorTextSecondary, rgba(0, 0, 0, 0.65));
  /* Base/Base Normal */
  font-family: "SFProTextRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}