input:focus {
    border: solid 1px var(--new-main, #3A78F1);
    outline: none;
}
input[type=radio]:focus{
    border: none;
}
.eulaAcceptanceContainer{
    display: flex!important;
    flex-direction: row!important;
    margin-top: 32px;
    margin-bottom: 32px;
}
.eulaAcceptanceContainer input {
    border-radius: 4px;
    background: var(--new-main, #3A78F1);
}
.warningCheckbox {
    border-color: var(--red, #F84949);
}
.eulaLink {
    color: var(--new-main, #3A78F1);
}
.eulaLink:focus {
    outline: none;
    border-bottom: 1px solid var(--new-main);
}
.linkContainer {
    flex-direction: row!important;
}
.warningFieldRequired, .emailFieldRequired {
    left: 72rem;
    top: 32rem;
    position: absolute;
    display: flex!important;
    flex-direction: row!important;
    padding: 12px 16px 12px 12px;
    align-items: center;
    gap: 4px;

    border-radius: 8px;
    border: 1px solid var(--red, #F84949);
    color: var(--red, #F84949);
    background: #FFF;

    /* shadow */
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
}
.emailFieldRequired{
    top: 22rem;
}
.passwordWarning {
    left: 72rem;
    top: 28rem;
    position: absolute;
    display: inline-flex;
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    border-radius: 8px;
    border: 1px solid var(--table-table-stroke, #C1CDDE);
    background: #FFF;
}
.notSatisfiedValidationRule {
    color: var(--table-table-name, #9FAAB8);

    /* inter_16/16_regular_(24) */
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.passwordWarning > div {
    display: flex;
    flex-direction: row;
}
.passwordWarning > div > img {
    margin-right: 8px;
}
.errorModalOverlay {

    position: fixed;
    inset: 0px;
    background-color: var(--gray-bg,  rgba(43, 49, 56, 0.70));
    display: flex;
}
.modalTitle{
    color: var(--black, #212121);

    /* inter_20/20_sb_(24) */
    font-size: 20px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
