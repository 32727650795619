.floating-episode-label {
  position: absolute;
  display: inline-flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.80);
  top: 12px;
  left: 12px;
  color: var(--colorTextLabel, rgba(255, 255, 255, 0.65));
}