.dropdownListContainer{
    position: absolute;
    max-height: 196px;
    min-height: 25px;
    flex-grow: 1;
    gap: 8px;

    border-radius: 8px;
    border: 1px solid var(--table-table-stroke, #C1CDDE);
    background: #FFF;

    /* shadow */
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    overflow: scroll;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 16px;
    padding-bottom: 16px;
}
.dropdownListContainer > div {
    justify-content: space-between;
}
.dropdownListContainer input{
    flex-grow: unset;
}
.innerDropdownListContainer{
    gap: 8px;
    overflow: scroll;
}
.innerDropdownListContainer::-webkit-scrollbar,
.dropdownListContainer::-webkit-scrollbar {
    display: none;
}
.teamDDListElement{
    display: flex;
    gap:8px;
    width: 100%;
    cursor: pointer;
    z-index: 10;
}
.teamDDListElement > input {
    cursor: pointer;
}
.teamDDListElement > label {
    flex-grow: 1;
    cursor: pointer;
}
.bottomFilter{
    width: 100%;
    height: 35px;
    flex-shrink: 0;
    position: sticky;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 88.64%);
}
