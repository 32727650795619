.average-stat-value-container {
  border-radius: 8px;
  background: var(--controlItemBgHover, rgba(0, 0, 0, 0.04));
  display: flex;
  width: 90px;
  padding: 2px 8px 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}

.stat-average-value {
  color: var(--colorPrimaryText, #002C8C);

  /* Base/Base Strong */
  /* font-family: "SFProTextRegular"; */
  font-family: "SFProTextSemiBold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 157.143% */
}

.stat-percent-value {
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));
  font-family: "SFProTextRegular";
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 181.818% */
  letter-spacing: 0.33px;
}