.calendarContainer,
.rightSidePanel {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  /*width: 100%;*/
}

.contentContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  position: relative;
}

.wideScreenContainer .contentContainer {
  gap: 0;
}

.wideScreenContainer .pageContent {
  margin-top: 0;
}

.rightSidePanel {
  min-width: 344px;
  gap: 16px;
  margin-right: 24px;
}

.calendarFilters {
  /*widht: 100%;*/
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  flex: 0;
  /*margin-bottom: 16px;*/
}

.calendar {
  border-radius: 8px;
  border: 1px solid var(--table-table-stroke, #C1CDDE);
  background: #FFF;
  height: 100%;
  /*width: 100%;*/
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
}

.calendarFilter {
  display: block !important;
  border-radius: 8px;
  /*border: 1px solid var(--table-table-stroke, #C1CDDE);*/
  /*background: var(--white, #FFF);*/

  padding: 10px 24px;
  align-items: center;
  gap: 10px;
  border: 1px solid var(--table-table-stroke, #C1CDDE);
  background: var(--bg, #F5F5F7);

  color: var(--table-table-name, #9FAAB8);

  /* inter_16/16_medium_(24) */
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.activeCalendarFilter {
  color: var(--new-main, #3A78F1);
  background: var(--white, #FFF);

}

.monthSelector {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex: 0 1;
  color: var(--black, #212121);

  /* inter_20/20_sb_(24) */
  font-size: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.monthSelector>div:nth-child(1),
.monthSelector>div:nth-child(3),
.monthSelector>div:last-child {
  width: 24px;
  height: 24px;
  cursor: pointer;
  font-weight: 400;
}

.currentMonth {
  color: var(--black, #212121);

  /* inter_20/20_sb_(24) */
  font-size: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.weekDays {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.weekDays>div {
  width: calc(100% / 7);
  display: flex;
  padding: 0px 2px 12px 2px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  color: var(--table-table-name, #9FAAB8);
  text-align: center;

  /* inter_13/13_sb_(16) */
  font-size: 13px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;

}

.eventAddContainer {
  width: 310px;
  width: 100%;
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  border-radius: 8px;
  border: 1px solid var(--table-table-stroke, #C1CDDE);
  background: #E6EDFC;
}

.eventAddContainer::-webkit-scrollbar {
  display: none;
}

.daysContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0;
  justify-content: flex-start;
  flex-grow: 1;
}


.dayNumber {
  padding: 2px;
  display: flex;
  width: 24px;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative;
}

.eventCreationFormHeader {
  color: var(--black, #212121);

  /* inter_20/20_sb_(24) */
  font-size: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.eventCreationFormHeader .disabled {
  color: var(--black, #212121);

  /* inter_20/20_sb_(24) */
  font-size: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.eventCreationForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}

.eventTypesContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.eventTypeSelector {
  cursor: pointer;

  display: flex;
  padding: 4px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--white, #FFF);
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  border-radius: 8px;
  background: var(--table-table-stroke, #C1CDDE);
}

.eventTypeSelector.activeEventType {
  border-radius: 8px;
  background: #3A78F1;
  color: var(--white);
}

.eventTeamContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-grow: 1;
  justify-content: space-between;
  gap: 4px;
}

.eventTeamContainer div {
  display: flex;
}

input::placeholder,
input::-webkit-input-placeholder {
  color: var(--table-table-name, #9FAAB8);
  font-size: 16px;
  font-family: "SFProTextRegular";
  font-style: normal;
  font-weight: 400;
}

.hostSelectorContainer {
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid var(--table-table-stroke, #C1CDDE);
  background: var(--white, #FFF);
  display: flex;
  padding: 4px;
  align-items: flex-start;
}

.eventTeamContainer input {
  flex-grow: 1;
  line-height: 24px;
}

.hostSelectorContainer>div {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  flex: 1 0 0;
  align-self: stretch;
  line-height: 16px;
  border-radius: 6px;
}

.activeHostSelection {
  background-color: var(--new-main);
  color: var(--white);
}

.eventDescriptionContainer {
  display: flex;
  width: 100%;
  line-height: 24px;
}

textarea {
  border-radius: 8px;
  border: 1px solid var(--table-table-stroke, #C1CDDE);
  background: var(--white, #FFF);


  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  flex-grow: 1;
  display: flex;
  height: 96px;
  padding: 12px 16px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  resize: none;
}

textarea:focus,
select:focus {
  border: solid 1px var(--new-main, #3A78F1);
  outline: none;
}

textarea::placeholder {
  color: var(--table-table-name, #9FAAB8);
}

.eventDateContainer {
  width: 100%;
}

.eventDateContainer,
.eventDateContainer>div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}

.eventDateContainer {
  gap: 16px;
}

.eventDateContainer>div:nth-child(1) {
  gap: 4px;
}

.eventDateContainer input {
  padding: 8px 8px;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

input[name=eventDay],
input[name=eventHour],
input[name=eventMinute] {
  width: 22px;
}

input[name=eventYear] {
  width: 45px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

select {
  border-radius: 8px;
  border: 1px solid var(--table-table-stroke, #C1CDDE);
  background: var(--white, #FFF);
  color: var(--black, #212121);
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  max-width: 90px;
  padding: 9px 4px 9px 0px;
  align-items: center;
  gap: 2px;
}

.hr {
  height: 0.5px;
  background-color: var(--table-table-name);
  margin: 10px 0 10px 0px;
  width: 100%;
}

.cameraControlContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.cameraSelectorContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selectedCamera {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

}

.cameraIsDisabled {
  opacity: 0.30000001192092896;
}

.cameraIsDisabled .selectedCamera,
.cameraIsDisabled .timeZoneSelector,
.cameraIsDisabled .cameraControlButtonContainer,
.cameraIsDisabled .cameraControlButtonContainer>button {
  cursor: default;
}

.videoUploaderContainer {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
}

.clearButton {
  cursor: pointer;

  color: var(--gray-light2, #576170);
  text-align: right;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}