.game-selection-title {
  color: var(--colorText, rgba(0, 0, 0, 0.88));

  /* LG/LG Strong */
  font-family: "SFProTextRegular";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  display: flex;
  /* padding: 16px 24px 8px 24px; */
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}