.goal-details {
  height: 24px;
}

.visible-goal-details {
  flex-direction: row-reverse;
  min-width: 32px;
  transition: width 0.2s linear;
}

.goal-icon {
  align-content: center;
  width: 22px;
  transition: left 0.2s linear;
}

.goal-minute {
  width: 0px;
  display: inline-block;
  opacity: 0;
  transition: width 0.2s linear;
  transition: opacity 0.2s linear;
  text-align: center;
}

.play-event {
  position: relative;
  width: 0px;
  right: -20px;
  height: 20px;
  z-index: -20;
  transition: right 0.2s linear;
}

.visible-goal-details .play-event {
  transition: right 0.4s linear;
}

.visible-goal-details:hover .play-event {
  width: 20px;
  height: 20px;
  right: 0;
  z-index: 20;
  transition: right 0.2s linear;
}

.visible-goal-details:hover .goal-minute {
  opacity: 1;
  width: 24px !important;
  transition: width 0.4s linear;
  transition: opacity 1s linear;
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));

  /* SM/SM Normal */
  font-family: "SFProTextRegular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}

.visible-goal-details:hover {
  border-radius: 8px;
  background-color: white;
  display: flex;
  height: 24px;
  min-width: 50px;
  padding: 2px 7px 2px 6px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  opacity: 1;
  transition: background-color 0.2s linear;
}

.visible-goal-details:hover.expanded-details {
  background: white;
}

.visible-goal-details:hover .goal-icon {
  left: 0px !important;
  width: 18px;
  transition: left 0.2s linear;
  background-color: transparent !important;
  border: none !important;
}