.filterPanels {
    display: flex;
    align-items: stretch;
    gap: 32px;
}

.bottomFilterController {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    line-height: 24px;
}

.leftPanel {
    display: flex;
    min-width: 187px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    height: 100%;
    overflow-y: auto;
}

.teamFilterSelector.activeFilterTeam,
.active>label {
    color: var(--white, #FFF) !important;
}

.rightPanel {
    /* display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    border-radius: 8px; */
    display: flex;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0 0;
    overflow: auto;
}

.rightPanel>form {
    width: 100%;
}

/* .rightPanel>div:first-child{
    display: flex;
    align-items: center;
    gap: 6px;
    align-self: stretch;
} */
.rightPanel>div:nth-child(2) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 32px;
    flex-grow: 1;
}

.playersColumn {
    /* gap: 32px;
    overflow-y: auto;
    flex-wrap: wrap;
    justify-content: space-between; */
    /* grid-template-columns: repeat(2, 1fr); /* Two equal columns */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0 0;
    flex-wrap: wrap;
    flex-direction: row;
}

.columnBlock {
    width: 240px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    text-align: left;
}

/*.columnBlock>div:first-child{*/
/*    border-bottom: 1px #9FAAB8 solid;*/
/*    width: 100%;*/
/*}*/
.player_role_name {

    display: flex;
    width: 240px;
    padding-bottom: 8px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    align-self: stretch;

    color: var(--colorTextHeading, rgba(255, 255, 255, 0.85));

    /* Heading/Heading 5 */
    font-family: "Roboto Condensed";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: 0.96px;
    text-transform: uppercase;
}

.columnBlock>div.hr {
    height: 1px;
    background-color: #9FAAB8;
    width: 100%;
    margin: 0px !important;
}

.columnBlock>div {
    display: flex;
    gap: 6px;
}

.filtersActive {
    color: var(--white, #FFF);
    /* inter_16/16_medium_(24) */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
}