.blur-zone {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: rgba(255, 255, 255, 0.5); */
  /* light blur color */
  /* backdrop-filter: blur(10px); */
  z-index: 100;
}




.overlay-a {
  position: absolute;
  background: transparent;
}

.rectangle {
  background: transparent;
  /* Clear the blur in the rectangle */
  backdrop-filter: none !important;
}

.rectangle .resize-handle {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  /* Match the height of the rectangle */
  width: 10px;
  /* Resize handle width */
  cursor: ew-resize;
  /* Indicates horizontal resizing */
  background: rgba(0, 0, 0, 0.2);
  /* Slightly darker for visibility of the resize handle */
}
.videoPlayerWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.videoPlayerWrapper>video {
  border-radius: 10px;
}