.filterContainer {
    width: 772px;
    height: 520px;
    padding: 16px 24px 24px 24px;
    flex-direction: column;
    align-items: flex-end;
    gap: 24px;
    flex-shrink: 0;
    position: absolute;
    display: flex;

    top: 20rem;
    right: 74px;
    z-index: 10;
    overflow: hidden;
    justify-content: space-between;

    border-radius: 8px;
    background: var(--colorBgElevated, #3D4249);
    /* boxShadow */
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
    /* transition: opacity 0.3s ease, transform 0.3s ease; */
}

.filterButton {
    font-family: Inter !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    letter-spacing: 0em !important;
    text-align: left !important;

    color: var(--colorTextHeading, rgba(255, 255, 255, 0.85));

    /* LG/LG Normal */
    font-family: "SF Pro Text";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */

    background: var(--colorBgLayout, #292D32);
    border-radius: 8px;

    padding: 8px 24px !important;
    justify-content: center;

    gap: 8px;
    flex: 1 0 0;

    display: flex !important;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
}

.filtersSelectionButtons:before,
.filtersSelectionButtons:after,
.filterButton:after {
    content: none !important;
}

.filters_horizontal_menu {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}