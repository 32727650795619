.example-thumb {
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  top: -5px;
  border-radius: 5px;
  border: 1px solid var(--white, #FFF);
  background: var(--new-main-light, #3A78F1);
  cursor: pointer;
}

.example-track-0 {
  height: 3px;
  flex-shrink: 0;
  border-radius: 3px;
  background: var(--table-table-stroke, #3A78F1);
}

.example-track-1 {
  height: 3px;
  flex-shrink: 0;
  border-radius: 3px;
  background: var(--new-main-light, #3A78F1);
}

.example-track-2 {
  height: 3px;
  flex-shrink: 0;
  border-radius: 3px;
  background: var(--table-table-stroke, #3A78F1);
}

.ant-checkbox+span {
  color: var(--colorTextSecondary, rgba(255, 255, 255, 0.65));
}

.ant-checkbox-checked+span {
  color: var(--colorTextHeading, rgba(255, 255, 255, 0.85));
}

.year_slider {
  margin: 0px !important;
  margin-left: 7px !important;
  margin-right: 7px !important;
}

.ant-slider-handle::after {
  background-color: var(--colorPrimaryHover, rgba(60, 137, 232, 1)) !important;
}

.dark_text {
  color: var(--colorTextDescription, rgba(255, 255, 255, 0.45));

  /* Base/Base Normal */
  font-family: SFProTextRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.light_text {
  color: var(--colorTextHeading, rgba(255, 255, 255, 0.85));

  /* Base/Base Normal */
  font-family: SFProTextRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}