.multitool-content-grid {
  display: grid;
}

@media screen and (max-width:1280px) {
  .multitool-content-grid {
    grid-template-columns: repeat(9, 1fr);
    grid-gap: 16px;
  }
}

@media screen and (max-width:1439px) {
  .multitool-content-grid {
    grid-template-columns: repeat(9, 1fr);
    grid-gap: 24px;
  }

  .multitool-left-column {
    grid-column: 1 / 6;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 24px;
  }

  .multitool-matches-column {
    grid-column: 1 / 3;
  }

  .multitool-metrics-column {
    grid-column: 3 / 6;
  }

  .multitool-maps-column {
    grid-column: 6 / 10;
  }

}

@media screen and (min-width: 1440px) and (max-width:1648px) {
  .multitool-content-grid {
    grid-template-columns: repeat(9, 1fr);
    grid-gap: 24px;
  }

  .multitool-maps-column {
    grid-column: span 4;
  }

  .multitool-left-column {
    grid-column: span 5;
  }

  .multitool-left-column {
    /* display: flex;
    justify-content: space-between;
    gap: 24px; */
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 24px;
  }

  .multitool-matches-column {
    /* width: 40%; */
    grid-column: span 2;
  }

  .multitool-metrics-column {
    grid-column: span 3;
    /* flex: 1; */
    /* width: 60%; */
    /* max-width: 446px; */
  }
}

@media screen and (min-width:1649px) {

  .player-page-controller,
  .team-page-controller {
    max-width: 1648px;
  }

  .multitool-content-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
  }

  .multitool-maps-column {
    grid-column: span 1;
  }

  .multitool-left-column {
    grid-column: span 1;
  }

  .multitool-left-column {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    /* display: grid;
    grid-template-columns: repeat(4.5, 1fr);
    grid-gap: 24px; */
  }

  .multitool-matches-column {
    min-width: 40%;
    /* grid-column: span 3; */
  }

  .multitool-metrics-column {
    /* grid-column: span 4; */
    flex: 1 1 100%;
    width: 60%;
    /* max-width: 446px; */
  }
}