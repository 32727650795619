.navigationPanelV2 {
  position: fixed;
  top: 0px;
  height: 56px;
  width: 100%;
  /*height: 896px;*/
  padding: 0px 12px;

}

.navigationPanel.light {
  background: #FFF;
  border-right: 1px solid var(--table-table-stroke, #C1CDDE);
}

.navigationPanel.dark {
  background: var(--colorBgLayout, rgba(41, 45, 50, 1));
}

.navigationPanel>div {
  display: inline-flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  flex-shrink: 0;
}

.navigationButton {
  cursor: pointer;
  padding: 12px;
  height: 24px;
}

.divider {
  /* border: solid 1px;
    border-color: var(--table-table-stroke, #C1CDDE);
    margin-top: 12px;
    margin-bottom: 12px; */

  height: 4px;
  align-self: stretch;
  background: var(--colorFillTertiary, rgba(255, 255, 255, 0.08));
}

.divider_thin {
  width: 48px;
  height: 1px;
  flex-shrink: 0;
  background: var(--gray-light2, #576170);
}

.activeButton.light {
  border-radius: 8px;
  background: #F3F3F5;
}

.activeButton.dark {
  background: var(--gray-light, #3F4752);
  border-radius: 8px;
}

.topBlockV2 {
  margin: 32px 0px;
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.bottomBlock {
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar-logo-container {
  display: flex;
  flex: 1 1;
  width: 70px !important;
  height: 56px !important;
  /* padding: 24px 32px; */
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.navbar-menu-container {
  flex: 1 1;
  display: flex;
  justify-content: flex-end;
  /* padding: 0px 32px; */
}

.vertical-header-divider {
  display: flex;
  width: 1px;
  height: 24px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  margin: 0px 32px;
  flex-shrink: 0;
  border-color: #424242;

  background-color: #424242;
}

.navbar-button,
.navbar-logo {
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
  justify-content: center;
}

.search-cascader .ant-select-selector {
  border-radius: 6px;
  /* Adjust the value as needed */
  font-size: 14px;
}

.search-divider {
  display: flex;
  height: 33px;
  padding: 6px 287px 5px 16px;
  align-items: center;
  align-self: stretch;
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));
  text-align: center;

  /* Base/Base Normal */
  font-family: "SFProTextRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.search-value-wrapper {
  display: flex;
  padding: 0px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.search-value-wrapper>img {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
}

a.search-team-name {
  color: var(--colorText, rgba(0, 0, 0, 0.88));
}

.search-team-name {
  flex: 1 0 0;
  color: var(--colorText, rgba(0, 0, 0, 0.88));
  /* Base/Base Normal */
  font-family: "SFProTextRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.team-name {
  flex: 1 0 0;
  color: var(--colorPrimaryText, #002C8C);
  font-family: "Roboto Condensed";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.98px;
  text-transform: uppercase;
}

.stats-header {
  color: rgba(0, 0, 0, 0.45);
  text-align: right;

  /* Base/Base Strong */
  font-family: "SFProTextRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.25);
  /* 157.143% */
}

.stats-name,
.stats-header,
.stat-header>span {
  /* opacity: 0.45;
  --webkit-opacity: 0.45;
  --moz-opacity: 0.45; */
  color: rgba(0, 0, 0, 0.45);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.25);
}

.block-column-title {
  display: flex;
  height: 40px;
  padding: 8px 0px;
  align-items: center;
  gap: 18px;
  align-self: stretch;
  margin: 12px 8px 0px 16px;
}

.search-section-title {
  display: flex;
  height: 33px;
  padding: 6px 287px 5px 16px;
  align-items: center;
  align-self: stretch;
}

.search-section-title>div {
  display: flex;
  padding: 0px 40px;
  align-items: center;
  gap: 10px;
  color: var(--colorTextDescription, rgba(255, 255, 255, 0.45));
  text-align: center;

  /* Base/Base Normal */
  font-family: "SFProTextRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.search-row {
  padding: 0 8px;
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--colorInpuText, rgba(255, 255, 255, 0.85));
}

.row-bordered {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.search-row>img {
  width: 32px;
  height: 32px;
}

.ant-select-item-group {
  padding: 6px 16px !important;
}

.ant-select-item-group[title]:not([title=""]) {
  padding: 6px 40px !important;
  color: var(--colorTextDescription);
}

.navbar-button:hover,
.active-navbar-button {
  border-radius: 6px;
  background: var(--colorBgTextHover, rgba(255, 255, 255, 0.12));
}

.download-notification-counter {
  background-color: var(--colorPrimaryBase, rgba(22, 104, 220, 1));
  color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  border: 1px solid var(--colorBgContainer, #141414);
  position: absolute;
  left: 50px;
  top: 10px;
}

@media screen and (max-width: 1280px) {
  .header-controller {
    padding: 0px 16px;
  }
}
@media screen and (min-width: 1281px) {
  .header-controller {
    padding: 0px 32px;
  }
}

/* @media screen and (min-width: 1648px) {
  .header-controller {
    padding: 0px 30px;
  }
}

@media screen and (min-width: 1690px) {
  .header-controller {
    padding: 0px 10px;
  }
}

@media screen and (min-width: 1700) {
  .header-controller {
    padding: 0px 0px;
  }
} */

.custom-option {
  padding-inline-start: 8px !important;
}

.rc-virtual-list-holder-inner {
  gap: 8px;
}

.ant-select-selection-search-input {
  font-size: 14px !important;
}