a {
  text-decoration: none;
}

.loginFormContainer {
  display: flex;
  flex-direction: column;
  width: 1440px;
  margin: auto;
  padding: 239px 0px 238px 0px;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 897px) {
  .loginFormContainer {
    width: 100% !important;
    height: 100%;
    justify-items: center;
    padding: unset !important;
  }

  div.loginForm {
    width: auto !important;
  }
}

.loginFormHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  color: var(--black, #212121);
  text-align: center;
  /* inter_25/25_sb_(32) */
  font-size: 25px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.loginForm {
  width: 405px;
}

.inputWithIcon,
.inputWithIcon>input {
  width: 100%;
}

.inputWithIcon {
  flex-direction: row-reverse;
}

.loginForm>form>div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
}

.loginForm>form>div:nth-child(1) {
  margin-top: 56px;
}

.loginForm>form>div:nth-child(2) {
  margin-top: 24px;
}

.loginForm>form>div>div {
  display: flex;
  flex-direction: row-reverse;
}



input {
  display: flex;
  padding: 12px 16px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--table-table-stroke, #C1CDDE);
  background: var(--white, #FFF);
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  margin-top: 32px;
}

.buttonContainer button {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
  color: var(--new-main, #3A78F1);
  cursor: pointer;
  /* inter_16/16_medium_(24) */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  border-radius: 8px;
  border: 1px solid var(--new-main, #3A78F1);
  background: var(--white, #FFF);
}

.buttonContainer button:focus {
  border: 1px solid var(--new-main, #3A78F1);
}

.linkContainer {
  margin-top: 12px;
  display: flex;
  width: 313px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center !important;
  gap: 30px;
}

.linkContainer>a {
  color: var(--new-main, #3A78F1);
  /* inter_16/16_medium_(24) */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

a:focus {
  outline: none;
}

.linkContainer>a:focus {
  outline: none;
  border-bottom: 1px solid var(--new-main);
}

.hidePasswordIcon {
  cursor: pointer;
  position: relative;
}