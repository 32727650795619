.team-league-name {
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));
  /* SM/SM Normal */
  font-family: "SFProTextRegular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}
.segment-class{
  min-width: unset!important;
}
.segment-class > .ant-segmented-item-label {
  padding: 0 12px!important;
}