.newProgressBar {
  /*margin: 1rem 0 0.5rem 0;*/
  /*width: 100%;*/
  height: 5px;
  background-color: var(--gray-light, #3F4752);
  position: relative;
  cursor: pointer;
}

.viewedProgressBar {
  background-color: var(--new-main-light);
  position: absolute;
  height: 100%;
  transform-origin: 0;
  z-index: 55;
  width: 100%;
  border: 1px transparent;
  border-radius: 8px;
}

.cameraCheckWindow_VideoControls {
  width: 80%;
  min-height: 112px;
  justify-content: flex-end;
  padding-left: 16px;
  padding-right: 16px;
  bottom: 0;
  /* padding-bottom: 6px; */
}

.scroller {
  width: 14px;
  border-radius: 10px;
  opacity: 1;
  background-color: var(--new-main-light);
  position: absolute;
  height: 14px;
  top: -4px;
  left: 0px;
  transform-origin: 0;
}

.scroller:hover {
  cursor: pointer;
}

.positionLine {
  /*z-index: 1000   ;*/
  opacity: 0.5;
  border-left: solid #212121 1px;
  height: calc(100vh - 60vh);
  position: relative;
  left: 6px;
  top: 14px;
}

.scroller.passive {
  background-color: var(--white);
}