.sliderRadio {
  display: inline-flex;
  padding: 4px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--gray-light, #3F4752);
  background: var(--gray-gray, #2B3138);
}

.videoTypeSelector.activeEventType {
  color: var(--white);
  border-radius: 6px;
  background: var(--gray-light-2, #576170);
}

.videoTypeSelector {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 6px;
  color: var(--table-table-stroke);
}

span {
  /* color: var(--table-table-name); */
}

.vl {
  width: 0.5px;
  background-color: var(--table-table-name);
  /*margin: 10px 0 10px 0px;*/
  height: 100%;
}

.topPanel {
  padding: 0rem 0rem 1rem 0rem;
  position: sticky;
  top: 0;
  z-index: 10;
  /*outline: 1px solid;*/
  /*outline-offset: 5px;*/
  /*outline-color: var(--gray-light2);*/
}

.videoPlayer>video {
  border-radius: 10px;
  /* max-height: var(--video-height) !important; */
}

.videoPlayer-h100>div {
  height: 100% !important;
}

.videoSelector {
  position: relative;
  border-bottom: 0;
  border: 1px solid transparent;
  border-radius: 8px 8px 0 0;
  min-width: 220px;
  padding: 12px 12px 0px 12px;
}

.videoSelector.opened {
  border: 1px solid var(--gray-light2);
}

.videoDetailsSelectorContainer {
  position: absolute;
  z-index: 10;
  background: rgba(27, 29, 33, 10);
  top: 30px;
  left: -1px;
  color: var(--white) !important;
  min-width: 220px;
  border: 1px solid var(--gray-light2);
  border-top: 0;
  padding: 12px 12px 12px 12px;
  border-radius: 0px 0px 8px 8px;
}

.videoPlayerElementsTooltip {
  color: var(--black);
  padding: 2px 5px;
  border-radius: 4px;
}

.editRangeScroller {
  background-color: var(--table-table-name, #9FAAB8);
  z-index: 300;
}

.playerLoaderContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  display: flex;
  margin: auto;
}

.bottomScrollerElement {
  background: #1B1D21;
}

::-webkit-scrollbar,
.bottomScrollerElement::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  /* Width of the scrollbar */
  border-radius: 2px;
}

::-webkit-scrollbar-track,
.bottomScrollerElement::-webkit-scrollbar-track {
  background: transparent;
  /* Background color of the track */
}

::-webkit-scrollbar-thumb,
.bottomScrollerElement::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover,
.bottomScrollerElement::-webkit-scrollbar-thumb:hover {
  background: #888;
  /* Color of the scrollbar thumb on hover */
}

.videoPlayer {
  position: relative;
  aspect-ratio: 16/9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.videoPlayer>div>video {
  border-radius: 10px;
}

/* .videoPlayer.auto-height>div {
  height: auto !important;
  width: auto !important;
} */

.videoPlayer-auto-height>div:first-child {
  height: auto !important;
}

.noVideoTitle {
  overflow: hidden;
  color: var(--character-secondary-45, rgba(255, 255, 255, 0.45));
  text-align: center;
  text-overflow: ellipsis;

  /* H4/medium */
  font-family: "SF Pro Text Bold";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 140% */
}

.noVideoContent {
  color: var(--character-secondary-45, rgba(255, 255, 255, 0.45));
  text-align: center;

  /* Body/regular */
  font-family: "SFProTextRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.active-video-action {
  border-radius: 8px;
  padding: 5px;
  background: var(--colorBgSpotlight, #4D5765);
}

.video-spinner >.ant-spin-text {
  text-shadow: none!important;
  text-wrap: nowrap !important;;
}
.video-spinner{
  color: var(--colorTextLabel, rgba(255, 255, 255, 0.65));
}