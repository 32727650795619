.title-grey-small {
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));
  text-align: right;
  padding-bottom: 4px;
  padding-top: 8px;

  /* SM/SM Strong */
  font-family: "SFProTextRegular";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-left: 20px;
  text-align: start;
  /* 166.667% */
}

.phase-hover>div.no-hover:hover {
  background: none;
}

.phase-hover>div:hover {
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.06);
}
.phase-hover>div {
  padding-left: 12px;
  padding-right: 12px;
}
.phase-hover>.title-grey-small {
  padding-left: 16px;
  padding-right: 16px;
}