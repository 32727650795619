.rectangle {
  position: absolute;
  height: 100%;
  border: 1px solid #F0F0F0;
  cursor: move;
  z-index: 100;
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, .5)
}

.handle {
  position: absolute;
  background-color: var(--Neutral-8, #595959);
  border: solid 1px #F0F0F0;
  border-radius: 50%;
  width: 6px !important;
  height: 6px !important;
  z-index: 10;
}

.handle-n::before,
.handle-s::before,
.handle-e::before,
.handle-w::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background-color: var(--Neutral-8, #595959);
  border: solid 1px #F0F0F0;
  /* Adjust the color as needed */
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.handle-se {
  bottom: -3px !important;
  right: -3px !important;
  cursor: se-resize;
}

.handle-sw {
  bottom: -3px !important;
  left: -3px !important;
  cursor: sw-resize;
}

.handle-ne {
  top: -3px !important;
  right: -3px !important;
  cursor: ne-resize;
}

.handle-nw {
  top: -3px !important;
  left: -3px !important;
  cursor: nw-resize;
}