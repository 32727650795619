.warning{
    border-radius: 8px;
    border: 1px solid var(--red, #F84949)!important;
    background: #FFF;
}
.warningModal {
    position: absolute;
    display: flex;
    padding: 8px 12px;
    flex-direction: column;
    width: 270px;
}
.warningModal > div > div {
    color: var(--red);
}
