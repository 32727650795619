.field-class {
  /* border: 1px solid red; */
  background-image: url('../../../../assets/img/FieldFootbal.svg');
  width: 100%;
  /* height: 100%; */
  /* min-height: 237px; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  aspect-ratio: 265/173;
}

.field-footzal-class {
  /* border: 1px solid red; */
  background-image: url('../../../../assets/img/FieldFootzal.svg');
  width: 100%;
  /* height: 100%; */
  /* min-height: 237px; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  aspect-ratio: 598/300;
}

.field-cell {
  z-index: 20;
  position: relative;
  padding-left: 2px;
  padding-bottom: 2px;

  border: 1px solid var(--colorSplit, rgba(0, 0, 0, 0.06));
  display: flex;
  justify-content: center;
  align-items: center;
}

.field-cell.drag-selected {
  border: 1px solid var(--colorPrimaryBase, #1677FF) !important;
  outline: 1px solid var(--colorPrimaryBase, #1677FF) !important;
  /* outline-offset: -1px!important; */
}

.cell-id {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.field-cell.with-value {
  background: rgba(22, 104, 220, 0.1);
  cursor: pointer;
}

.field-cell.with-value.selected {
  background: rgba(22, 104, 220, 0.2);
  border: 1px solid rgba(22, 119, 255, 1);
}

.field-cell.with-value.opposite-selected {
  background: rgba(22, 220, 91, 0.2);
}

.selected {
  color: rgba(22, 104, 220, 1) !important;
}

.field-cell.with-value:hover {
  border: 1px solid rgba(64, 150, 255, 1);
}

.field-cell.with-value>.value {
  color: var(--colorPrimaryText, #002C8C);
}

.field-cell.with-value.non-selected {
  background: rgba(0, 0, 0, 0.15);
  color: rgba(0, 0, 0, 0.25) !important;
}

.field-cell.with-value.non-selected>.value {
  color: rgba(0, 0, 0, 0.25) !important;
}

.field-cell>.value {

  text-align: center;
  /* Heading/Heading 5 */
  font-family: "Roboto Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.96px;
  text-transform: uppercase;
}

.field-cell.small-value:hover>.value {
  color: var(--colorPrimaryText, #002C8C);
  text-align: center;

  /* Heading/Heading 4 */
  font-family: "Roboto Condensed";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  /* 140% */
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.field-cell>span {
  position: absolute;
  bottom: 0;
  left: 2px;
  color: var(--colorTextDisabled, rgba(0, 0, 0, 0.25));
  font-family: "SFProTextRegular";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  /* 140% */
}

.field-cell.medium-value {
  opacity: 0.2;
  background: var(--colorPrimaryBase, #1668DC);
}

.field-cell.medium-value>.value {
  color: var(--colorPrimaryText, #002C8C);
  text-align: center;

  /* Heading/Heading 5 */
  font-family: "Roboto Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.96px;
  text-transform: uppercase;
}

.field-cell.medium-value:hover>.value {
  color: var(--colorPrimaryText, #002C8C);
  text-align: center;

  /* Heading/Heading 4 */
  font-family: "Roboto Condensed";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  /* 140% */
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.comet {
  position: absolute;
  z-index: 10;
}

.comet.head {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  /* color: white; */
}

.comet.tail {
  border-radius: 50%;
  width: 5px;
  height: 5px;
}

.comet.head.Success,
.comet.tail.Success {
  background-color: #4096FF;
  border: 1px solid rgba(255, 255, 255, 0.85);
}

.comet.head.Success.asPoint,
.comet.tail.Success.asPoint {
  background-color: #1677FF;
}

.comet.head.Success.selected,
.comet.head.Fail.selected,
.comet.tail.Success.selected,
.comet.tail.Fail.selected {
  border: 1px solid #0958D9;
}

.comet.head.Success.opaque,
.comet.head.Fail.opaque,
.comet.tail.Success.opaque,
.comet.tail.Fail.opaque,
.comet-tail.Success.opaque,
.comet-tail.Fail.opaque {
  opacity: 0.4;
}

.comet-tail.Success {
  border-bottom-color: rgba(64, 150, 255, 0.3) !important;
}

.comet-tail.Success.selected {
  border-bottom-color: rgba(64, 150, 255, 1) !important;
  z-index: 20;
}

.comet-tail.Fail {
  border-bottom-color: rgba(131, 131, 131, 0.3) !important;
}

.comet-tail.Fail.selected {
  border-bottom-color: rgba(131, 131, 131, 1) !important;
  z-index: 20;
}

.comet.head.Fail,
.comet.tail.Fail {
  background-color: #838383;
  border: 1px solid rgba(255, 255, 255, 0.85);
}

.comet.head.Fail,
.comet.tail.Fail.asPoint {
  background-color: #838383;
  border: 1px solid rgba(255, 255, 255, 0.85);
}

.comet.line.Success {
  position: absolute;
  /* height: 2px!important; */
  /* Line thickness */
  transform-origin: 0 50%;
  /* Distance between the circles */
  /* height: 2px; */
  /* Thickness of the line */
  background-color: #69B1FF;
}

.connector {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}


.comet.container {
  position: relative;
  width: 100px;
  /* Adjust the size of the canvas/container */
  height: 100px;
  /* Adjust as needed */
}

.comet.head-css {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
}

.comet.tail-css {
  border-radius: 50% / 10%;
}

.comet.head-css.Success {
  background-color: #69B1FF;
}

.comet.tail-css.Success {
  background-color: #69B1FF;
}