.custom_tab {
    display: flex;
    /* width: 106px; */
    padding: 8px 24px;
    align-items: center;
    gap: 8px;

    border-radius: 8px;
    color: var(--colorTextLabel, rgba(255, 255, 255, 0.65));
    background: var(--colorBgLayout, #292D32);
    text-transform: capitalize;

    color: var(--colorTextHeading, rgba(255, 255, 255, 0.85));

    /* LG/LG Normal */
    /* font-family: "SF Pro Text"; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}
.custom_tab.center {
    justify-content: center;
}
.custom_tab.left {
    justify-content: start;
}
.custom_tab.chosen {
    background: var(--colorBgSpotlight, #4D5765);
    color: var(--colorTextHeading, rgba(255, 255, 255, 0.85));
}
.custom_tab.horizontal {
    flex: 1 0 0;
}
.custom_tab.vertical{
    align-self: stretch;

    color: var(--colorTextHeading, rgba(255, 255, 255, 0.85));
    font-family: "Roboto Condensed";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.84px;
    text-transform: uppercase;
    min-height: 36px;
}