.average-stat-value-container {
  border-radius: 8px;
  background: var(--controlItemBgHover, rgba(0, 0, 0, 0.04));
  display: flex;
  width: 90px;
  padding: 4px 8px 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}

.team-stat-percent-value {
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));
  font-family: "SFProTextRegular";
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 218.182% */
  letter-spacing: 0.33px;
}