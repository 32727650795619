.team-player-stats-container {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
}

.team-player-stats-block {
  padding: 16px;
  border-radius: 12px;
  flex: 1 0 0;
  background: var(--white, #FFF);
  height: 100%;
}

.team-player-field-container {
  width: 153px;
  height: 100px;
  flex-shrink: 0;
  position: relative;
  mask-image: url('../../../assets/img/maskField.svg');
}

.team-player-field-container.footzal {
  width: 200px;
  mask-image: url('../../../assets/img/maskFieldFutzal.svg');
}

.team-player-name {
  overflow: hidden;
  color: var(--colorTextHeading, rgba(0, 0, 0, 0.88));
  text-overflow: ellipsis;

  /* Heading/Heading 4 */
  font-family: "Roboto Condensed";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  /* 140% */
  letter-spacing: 0.6px;
  text-transform: uppercase;
  text-wrap: nowrap;
  /* max-width: 226px; */
}

.team-player-team-name {
  width: fit-content;
  color: var(--colorTextLabel, rgba(0, 0, 0, 0.65));
  font-family: "Roboto Condensed";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.98px;
  text-transform: uppercase;
}

.team-player-team-name:hover {
  color: var(--colorPrimaryBase, rgba(22, 104, 220, 1));
}

.team-player-country-code {
  color: var(--colorTextLabel, rgba(0, 0, 0, 0.65));
  font-family: "Roboto Condensed";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.98px;
  text-transform: uppercase;
}

.span-label {
  color: var(--colorTextHeading, rgba(0, 0, 0, 0.88));
  text-overflow: ellipsis;
  /* Base/Base Strong */
  font-family: "SFProTextRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.no-data-span-label {
  color: rgba(0, 0, 0, 0.25);

  /* LG/LG Normal */
  font-family: "SFProTextRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 150% */
}

.ant-segmented-item {
  color: black;
}

.flag-icon {
  font-family: 'NotoEmojiFlag', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  display: flex;
  align-items: center;
  height: 20px;
}