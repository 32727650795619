.activeTournamentSelector{
    cursor: pointer;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid var(--table-table-stroke, #C1CDDE);
    background: var(--white, #FFF);

    display: flex;
    padding: 10px 16px;
    align-items: center;
    justify-items: center;
    justify-content: space-between;
    gap: 8px;
    flex-shrink: 0;
    flex-direction: row;
    color: var(--black);
    flex-grow: 1;
}
.activeTournamentSelector.opened {
    position: absolute;
    width: 100%;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 12px;
    z-index: 10;
    border-radius: 0 0 8px 8px;
    border-top: transparent;
}
.tournamentsSelectionList{
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: space-around;
    width: 100%;
    color: var(--table-table-name);
}
.activeTournamentSelector.topOpened {
    border-bottom: 0px;
    border-radius: 8px 8px 0 0;
    padding-bottom: 2px;
}
.activeTournamentSelector.topOpened > div {
    border-bottom: 1px solid var(--table-table-stroke);
    padding-bottom: 8px;
}
