.reels-edit-event-controller-wrapper{
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;

    gap: 8px;
    height: 48px;
    border-radius: 12px;
    background: var(--colorBgLayout, #292D32);
    z-index: 200;
}