.positions-filter-timer {
  color: var(--colorTextTertiary, rgba(0, 0, 0, 0.45));
  text-align: right;

  /* SM/SM Normal */
  font-family: "SFProTextRegular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}

.ant-select-selection-item .positions-filter-timer {
  color: var(--colorPrimaryBase, rgba(22, 104, 220, 1));
  line-height: 30px;
  font-size: 14px;
}

.ant-select-open .positions-filter-timer,
.ant-select-open .positions-filter-name {
  color: rgba(0, 0, 0, 0.25);
}

.playerStatsPopoverHandler:hover,
.hasSelection:hover>.ant-select-selector>.ant-select-selection-placeholder>div,
.hasSelection:hover>.ant-select>.ant-select-selector>.ant-select-selection-placeholder>.select-placeholder,
.hasSelection:hover>.ant-select>.ant-select-selector>.ant-select-selection-item,
.hasSelection:hover>.ant-select-selector>.ant-select-selection-placeholder>div,
.hasSelection:hover>.ant-select-selector>.ant-select-selection-item>div>.positions-filter-timer,
.hasSelection:hover>.ant-select-selector>.ant-select-selection-item>div>.positions-filter-name,
.select-placeholder:hover>.hasSelection>.ant-select-selector>.ant-select-selection-placeholder>.select-placeholder {
  color: rgba(64, 150, 255, 1) !important;
}