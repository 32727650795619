img.inputRightIcon {
    cursor: pointer;
}
.inputRightIcon{
    position: absolute;
    /*margin-left: -50px;*/
    margin-right: 16px;
}
div.inputRightIcon{
    color: var(--table-table-name, #9FAAB8);

    /* inter_13/13_regular_(16) */
    font-size: 13px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /*margin-left: 8px;*/
    justify-items: center;
    align-content: center;
    align-items: center;
}
.inputWithIcon {
    align-items: center;
    display: flex;
}
.inputWithIcon > input {
    padding-right: 40px;
    color: #212121;
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    align-items: center;
}
.inputWithIcon > textarea {
    display: flex;
    height: 96px;
    padding: 12px 16px;
    padding-right: 40px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}
textarea::-webkit-scrollbar {
    display: none;
}
.flex-start {
    align-items: flex-start;
}
.mt10 {
    margin-top: 10px;
}
