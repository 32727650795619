.team-profile-game-stat-row {
  position: relative;
}

.team-profile-game-stat-row:hover:after {
  /* .team-profile-game-stat-row>div>div>div>div.stat-value-container:hover ~ .team-profile-game-stat-row:after { */
  position: absolute;
  content: '';
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.06);
  margin-left: -12px;
  top: -4px;
  padding-top: 4px;
  padding-bottom: 4px;
  height: 32px;
  width: 100%;
}

.team-profile-game-stat-row:hover>div>div>div>div.stat-value-container,
.team-profile-game-stat-row:hover>div>div>div.stat-value-container {
  outline: 1px solid var(--colorPrimaryBorder, #91CAFF);
}

.team-profile-game-stat-row.child:hover:after {
  margin-left: -24px;
}

.team-profile-block-stats-column-title {
  padding-left: 12px !important;
  padding-right: 48px !important;
}

.team-match-row-team-name:hover {
  color: var(--colorPrimaryBase, rgba(22, 119, 255, 1));
}