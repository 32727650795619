.downloads-container {
  width: 100%;
  padding-top: 0px;
  padding-left: 30px;
  padding-right: 30px;
}

.downloads-table table {
  border-spacing: 0 12px;
}

.downloads-table .ant-checkbox-inner {
  border-spacing: initial;
}

.downloads-table-row {
  background-color: var(--colorBgLayout);
  color: var(--colorText);
  /* cursor: pointer; */
}

.downloads-table-row>td {
  border-bottom: none;
}

.downloads-table-row>td:first-child {
  border-radius: 12px 0 0 12px;
}

.downloads-table-row>td:last-child {
  border-radius: 0 12px 12px 0;
}

.download-action-icon-container {
  border-radius: 6px;
  border: 1px solid var(--colorBorder, #424242);
  background: var(--colorBgLayout, #292D32);
  box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.04);
  min-width: 32px;
  min-height: 32px;
}

.delete-action-icon-container {
  width: 32px;
  height: 32px;
}

.download-action-icon-container:hover,
.delete-action-icon-container:hover {
  border-radius: 6px;
  background: var(--colorBgTextHover, rgba(255, 255, 255, 0.12));
}

.mass-download-modal {
  display: flex;
  height: 34px;
  width: 304px;
  align-items: center;
  gap: 8px !important;
}

div.ant-notification-bottomRight:has(> .ant-notification-notice-wrapper div.mass-download-modal) {
  right: 0px !important;
}

.download-name {
  padding: 16px 16px 16px 0px !important;
}

.base-confirm-modal>div>div.ant-modal-content {
  padding: 32px 32px 24px 32px !important;
}

.primary-button {
  background: #1554AD !important;
}

.primary-button:hover {
  background-color: #3C89E8 !important;
}