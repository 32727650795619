input[type="range"].zoomerBar {
    -webkit-appearance: none;
    border: 0!important;
    border-radius: 50%!important;
}

input[type=range].styled-slider:focus {
    outline: none!important;
    border: 0!important;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: var(--new-main-light, linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), #3A78F1);
    border-radius: 50%;
    width: 14px;
    height: 14px;
    margin-top: -4px;
    appearance: none;
    cursor: pointer;
    /*margin-left: -6px;*/
}
input[type="range"]::-webkit-slider-runnable-track {
    -moz-appearance: none;
    height: 5px;
    background: #F0F3F7;
    color: #3A78F1;
    appearance: none;
    width: 100%;
}
.progressBar{

}
/*input[type="range"]::-webkit-slider-thumb:hover {*/
/*    display: block;*/
/*    position: relative;*/
/*    top: -20px;*/
/*    background-color: red;*/
/*    width: 10px;*/
/*    height: 10px;*/
/*}*/

.zoomerBar {
    padding: 0!important;
}
input[type="range"], input[type="range"].zoomerBar::-webkit-slider-runnable-track {
    -moz-appearance: none;
    height: 5px;
    background: #576170;
    color: #3A78F1;
    appearance: none;
    width: 100%;
    cursor: pointer;
}

.zoomerBar::-webkit-slider-runnable-track, input[type="range"].zoomerBar::-moz-range-track {
    height: 5px;
    -webkit-appearance: none;
    background-color: #576170;
    /*background-color: #d9d9d9;*/
}
.zoomerBar::-webkit-slider-runnable-track, input[type="range"].zoomerBar::-moz-range-progress {
    height: 5px;
    background-color: #F0F3F7;
    /*background-color: green;*/
}
.zoomerBar::-webkit-slider-runnable-track:focus, input[type="range"].zoomerBar::-moz-range-track:focus {
    border: none;
}
.zoomerBar::-webkit-slider-thumb, input[type="range"].zoomerBar::-moz-range-thumb {
    -webkit-appearance: none;
    background-color: #F0F3F7;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin-top: -4px;
}
.zoomerBar::-webkit-slider-thumb:focus, input[type="range"].zoomerBar::-moz-range-thumb:focus {
    border: none;
}
