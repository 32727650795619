.team-page-controller {
    display: flex;
    justify-content: space-between;
}
.block-title {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  padding: 8px 0;
}

.block-title-h {
  color: var(--colorPrimaryText, #002C8C);
  font-family: "Roboto Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.96px;
  text-transform: uppercase;
}

.block-content {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.block-column {
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;

  border-radius: 12px;
  /* border: 1px solid var(--colorBorder, rgba(0, 0, 0, 0.15)); */
  background: var(--colorBgElevated, #FFF);
}

.block-column-row {
  display: flex;
  padding: 4px 0px;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}
