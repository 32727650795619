.timingPanel{
    min-width: 832px;
}
.category{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
}
.category>div:first-child {
    margin-bottom: 6px;
}
.category > ul {
    margin: 0;
    padding: 0;
}
.category > ul >li {
    margin-top: 6px;
    padding: 0;
}
.videoAttributes {
    display: grid;
    /*grid-auto-flow: column; !* Make the items flow in columns *!*/
    grid-template-columns: repeat(3, 1fr); /* Three equal columns */
    gap: 10px; /* Adjust the gap between categories */
}
li.checkBoxItem{
    display: flex;
}
.filtersActive.title {
    color: var(--colorTextHeading, rgba(255, 255, 255, 0.85));

    /* Heading/Heading 5 */
    font-family: "Roboto Condensed";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.96px;
    text-transform: uppercase;
}