.greenDot{
    border-radius: 8px;
    width: 6px;
    height: 6px;
    background-color: #30C321;
}
.blueDot {
    border-radius: 8px;
    width: 6px;
    height: 6px;
    background-color: var(--new-main, #3A78F1);;
}

.gradientRight {
    width: 40px;
    height: 25px;
    flex-shrink: 0;
    position: absolute;
    right: 0px;
    background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
}


.advancedTooltip {
    border-radius: 6px!important;
    background: var(--gray-gray, #2B3138);
}
