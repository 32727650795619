.helper-button-text {
  color: var(--colorTextDescription, rgba(255, 255, 255, 0.45));
  font-family: "Roboto Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
  /* text-transform: uppercase; */
  /* width: 18px; */
  flex-shrink: 0;
}

.activeButtonHover.dark:hover {
  background: rgba(255, 255, 255, 0.12);
  border-radius: 8px;
}

.activeButtonHover.dark:hover svg path {
  fill-opacity: 1 !important;
  stroke-opacity: 1 !important;
}

.activeButtonHover.dark.fill:hover svg path {
  fill: white !important;
}

.activeButtonBottom {
  border-radius: 8px;
  background: var(--colorBgTextHover, rgba(255, 255, 255, 0.12));
}

.activeButtonBottom svg path {
  fill-opacity: 1 !important;
  stroke-opacity: 1 !important;
}

.activeButtonBottom.fill svg path {
  fill: white !important;
}

.fhd-badge>.ant-badge-count-sm {
  color: var(--white, #FFF);
  text-align: center;
  height: 20px!important;
  /* Condensed/SM Normal */
  font-family: "Roboto Condensed"!important;
  font-size: 12px!important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px!important;
  /* 166.667% */
  letter-spacing: 0.84px;
  text-transform: uppercase;
  border-radius: 8px!important;
}