.folder-name {
  overflow: hidden;
  color: var(--colorTextHeading, rgba(255, 255, 255, 0.85));
  text-overflow: ellipsis;

  /* Base/Base Normal */
  font-family: "SFProTextRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */

}

.folder-playlist-count {
  color: var(--colorTextQuaternary, rgba(255, 255, 255, 0.25));
  text-align: right;
  font-family: "Roboto Condensed";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
  text-transform: uppercase;
}

.selected-folder-to-move {
  background-color: var(--colorPrimaryBgHover);
  border-radius: 8px;
}