.custom-slider {
  position: relative;
  top: 11px;

}

/* .custom-slider>ant-slider-handle::before,
.custom-slider>ant-slider-handle::after {
  display: none !important;
  visibility: hidden;
  background: transparent !important;
  background-color: transparent !important;
} */

.custom-slider>.ant-slider-handle-2::after {
  display: block !important;
}

.custom-slider>div.ant-slider-handle-1:hover,
.custom-slider>div.ant-slider-handle-1:active,
.custom-slider>div.ant-slider-handle-1:active:hover,
.custom-slider>div.ant-slider-handle-1:active::before,
.custom-slider>div.ant-slider-handle-1:active::after,
.custom-slider>div.ant-slider-handle-2:hover,
.custom-slider>div.ant-slider-handle-2:active,
.custom-slider>div.ant-slider-handle-2:active:hover,
.custom-slider>div.ant-slider-handle-2:active::before,
.custom-slider>div.ant-slider-handle-2:active::before:hover,
.custom-slider>div.ant-slider-handle-2:active::after,
.custom-slider>div.ant-slider-handle-2:active::after:hover,
div.custom-slider>div.ant-slider-handle:active::after:hover,
div.custom-slider>div.ant-slider-handle:active::after {
  display: none;
  box-shadow: unset;
  border: 0px;
  content: none;
  border-color: transparent;
  background-color: transparent;
}

.custom-slider>.ant-slider-handle-1::after,
/* .custom-slider > .ant-slider-handle-1::after:hover, */
.custom-slider>.ant-slider-handle-1::before,
/* .custom-slider > .ant-slider-handle-1::before:hover, */
.custom-slider>.ant-slider-handle-2::after,
.custom-slider>.ant-slider-handle-2::before {
  background-color: transparent;
  content: none;
  border-radius: 0;
  box-shadow: unset;
  border: 0;
  width: 0;
  height: 0;
  border-color: transparent;
  background-color: transparent;
}

.handleclassx {
  background-image: url('../../../assets/img/leftHandleBlue.svg');
  background-size: cover;
  background-repeat: no-repeat;
  width: 6px !important;
  height: 20px !important;
  transform: rotate(0deg) !important;
  position: relative !important;
  margin-top: -9px !important;
  margin-left: -2px;
  z-index: 30;
}

.handleclassx~.handleclassx {
  background-image: url('../../../assets/img/leftHandleBlue.svg');
  transform: rotate(180deg) !important;
  margin-top: -20px !important;
}
.handleclassy{
  z-index: 5;
}
.custom-slider>.ant-slider-handle-2 {
  /* background-image: url('../../../assets/img/rightHandleBlue.svg');
    background-color: transparent;
    background-size: cover;
    box-shadow: unset;
    border: 0px;
    border-color: transparent;
    background-color: transparent;  
    background-repeat: no-repeat;
    width: 6px; 
    height: 20px;
    transform: rotate(-90deg);
    position: relative;
    top: -27px; */
}

div.custom-slider>div.ant-slider-step>span.ant-slider-dot {
  background-color: transparent;
  /* width: 3px; */
  /* height: 3px; */
}

.custom-keyframe-mark {
  background-color: 'red' !important;
}

.main-timeline-slider>.ant-slider-step {
  z-index: 100;
}

.main-timeline-slider>.ant-slider-step>.ant-slider-dot {
  border-radius: 3px !important;
  width: 8px;
  height: 8px;
  top: -3px;
  border: 2px solid #3C89E8 !important;
  background-color: #141414;
}

.buffering-timeline-slider {
  top: -12px;
  z-index: 3;
}