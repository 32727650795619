.bottomControlbutton > input:disabled{
    color: var(--table-table-name);
}
.bottomControlbutton > input:disabled.hasValue{
    color: var(--black);
}
.createdLinkContainer{
    display: flex;
    /*padding: 8px;*/
    gap: 8px;
    flex: 1 0 0;
    /*background: var(--white);*/
    color: var(--white, #FFF);
    /* inter_16/16_medium_(24) */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    text-wrap: nowrap;
}
.white-icon >path {
    fill: var(--Neutral-1);
}
.ant-notification-bottomRight{
  /* right: 0px!important; */
  bottom: 0px!important;
}
.link-create-notification{
    width: 328px!important;
    display: flex;
}
.link-create-notification .ant-notification-notice-description{
    margin-inline-start: 0!important;
    margin-right: 10px;

}
.link-create-notification .ant-notification-notice-content{
    display: flex;
    flex: 1;
}
.link-create-notification .ant-notification-notice-with-icon{
    flex: 1;
}
.ant-notification-notice-description{
    margin-right: 0!important;
}