.dailyEventsListModal{
    /*display: block;*/
    /*position: absolute;*/
    top: 10px;

    border-radius: 8px;
    border: 1px solid var(--table-table-stroke, #C1CDDE);
    background: var(--white, #FFF);
    box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.15);

    display: flex;
    width: 200px;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    z-index: 20;
    position: absolute;

    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}
.dailyEventsListModal span{
    color: var(--table-table-name, #9FAAB8);

    /* inter_13/13_regular_(16) */

}
.dailyEventsListModal > div:first-child {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}
