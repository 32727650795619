.resetPasswordFormContainer{
    display: flex;
    flex-direction: column;
    width: 1440px;
    margin: auto;
    padding: 239px 0px 238px 0px;
    justify-content: center;
    align-items: center;
    gap: 54px;
}
.loginForm{
    display: flex;
    flex-direction: column;
    gap:24px;
}
.invalidLinkMessage{
    padding: 10px 12px;
    margin-top: 2rem;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.buttonContainer .linkContainer{
    width: 100%;
    padding: 0;
    margin-top: 0;
}
.buttonContainer.disabled button {
    opacity: 0.4000000059604645;
}
