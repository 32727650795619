.custom-tooltip-dark {
  /* Base/Base Normal */
  font-family: "SFProTextRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  width: 226px;
  border-radius: 6px;
  border: 1px solid var(--colorBgTextHover, rgba(255, 255, 255, 0.12));
  /* 157.143% */
}