.timeZoneContainer {
    display: flex;
    flex-direction: column;
}
.timeZoneSelector {
    display: flex;
    align-items: center;
    color: var(--new-main, #3A78F1);
}


.eventCreationControlContainer{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
}
.cameraCheckPreview {
    width: 100%;
    height: 166px;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 8px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), lightgray 50% / cover no-repeat;
}
.playButtonThumbnail {
    position: relative;
    margin: auto;
    top: -130px;
    cursor: pointer;
}
.cameraPreviewContainer{
    margin-bottom: -25px;
}
.cameraPreviewContainer span {
    color: var(--table-table-name);
    cursor: pointer;
}
