.dropDownRadioContainer{
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 16px;
    padding-bottom: 16px;
    position: absolute;

    border-radius: 8px;
    border: 1px solid var(--table-table-stroke, #C1CDDE);
    background: #FFF;
    display: flex;
    flex-direction: column;
    z-index: 10;
}
.dropdownRadioListContainer{
    position: absolute;
    max-height: 196px;
    min-height: 25px;
    flex-grow: 1;
    gap: 8px;

    border-radius: 8px;
    background: #FFF;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    z-index: 10;
    overflow: scroll;
}
.dropdownRadioListContainer::-webkit-scrollbar {
    display: none;
}
