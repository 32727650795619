.calendarDropdownContainer{
    z-index: 20;
    position: absolute;
    width: 300px;
    height: 204px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid var(--table-table-stroke, #C1CDDE);
    background: var(--white, #FFF);

    /* shadow */
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
    padding: 15px 10px;
    gap: 16px;
    justify-content: space-between;
    flex-grow: 1;
}
.calendarDropdown_YearContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}
.calendarDropdown_YearContainer > div {
    display: flex;
    flex-direction: row;
}

.calendarDropdown_YearContainer > div:nth-child(2) {
    background: linear-gradient(to right, transparent,  rgba(159, 170, 184, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* inter_16/16_medium_(24) */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.calendarDropdown_YearContainer > div:nth-child(6){
    background: linear-gradient(to left, transparent, rgba(159, 170, 184, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* inter_16/16_medium_(24) */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.calendarDropdown_YearContainer > div:nth-child(3),
.calendarDropdown_YearContainer > div:nth-child(5) {
    color: var(--table-table-name, #9FAAB8);

    /* inter_16/16_medium_(24) */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.calendarDropdown_YearContainer > div:nth-child(4) {
    color: var(--black, #212121);

    /* inter_20/20_sb_(24) */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.calendarDropDown_Months {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    flex-grow: 1;
}
.monthDD {
    width: 51px;
    flex-grow: 1;
    /*width: 25%;*/
    display: flex;
    padding: 2px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 25px;
    color: var(--gray-gray, #2B3138);

    /* inter_16/16_regular_(24) */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.monthDD.activeMonth{
    display: flex;
    padding: 4px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #3A78F1;
    color: var(--white, #FFF);

    /* inter_16/16_medium_(24) */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
