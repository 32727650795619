.zero-value {
  color: var(--colorPrimaryText, #002C8C);
  /* Value/LG Strong */
  font-family: "Roboto Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
  /* text-transform: uppercase; */
}

.stat-consolidated-precent-value {
  color: var(--colorPrimaryText, #002C8C);
  /* SM/SM Strong */
  font-family: "SFProTextSemiBold";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 166.667% */
}